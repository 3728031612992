import React from 'react';
import { Redirect } from 'react-router-dom'

import authService from './modules/Auth/authService'
import permissions from './modules/Auth/permissions'
import CanCreateAlonePackage from './modules/Package/middlewares/CanCreateAlonePackage'
import UploadDamagedImagesMiddleware from './modules/Package/middlewares/UploadDamagedImagesMiddleware'
import CanManageDeliveryPartnerMiddleware from './modules/ShippingPartner/middlewares/CanManageDeliveryPartnerMiddleware'

import ConfigLayout from './layouts/Config'
import DefaultLayout from './layouts/Default'
import Page404 from './modules/Common/components/404'
import Page403 from './modules/Common/components/403'
import Home from './modules/Common/Home/Home'
import Login from './modules/Auth/Login/LoginContainer'
import LoginCallback from './modules/Auth/Login/LoginCallbackContainer'
import CreatePackage from './modules/Package/CreatePackageViaScan/CreatePackageContainer'
import LinkStamp from './modules/Package/LinkStamp/LinkPackageStampContainer'
import ListPackage from './modules/Package/ListPackage/ListPackageContainer'
import PackageDetail from './modules/Package/PackageDetail/PackageDetailContainer'
import CheckingPackage from './modules/Package/CheckingPackage/CheckingPackageContainer'
import UploadDamageImages from './modules/Package/UploadDamagedImages/UploadDamagedImagesContainer'
import ScanBarcode from './modules/ScanBarcode/ScanBarcodePage/ScanBarcodePageContainer'
import ScanningHistory from './modules/Barcode/ScanningHistory/ScanningHistoryContainer'
import BagPackingContainer from './modules/Bag/BagPacking/BagPackingContainer'
import BagPackingLastmileContainer from './modules/Bag/BagPackingLastmile/BagPackingContainer'
import BagDetail from './modules/Bag/BagDetail/BagDetailContainer'
import Manifest from './modules/Bag/Manifest'
import PERMISSION from './modules/Auth/permissions'
import authenticate from './modules/Auth/middleware/authenticate'
import loadAuthUser from './modules/Auth/middleware/loadAuthUser'
import loadSetting from './modules/Setting/middleware/loadSetting'
import { can, canAny } from './modules/Auth/middleware/authorize'
import CreateBag from './modules/Bag/CreateBag/CreateBagContainer'
import CreateBagLastmile from './modules/BagLastmile/CreateBag/CreateBag'

import ListWarehouse from './modules/WarehouseConfig/ListWarehouse/ListWarehouseContainer'
import CreateWarehouse from './modules/WarehouseConfig/CreateWarehouse/CreateWarehouseContainer'
import UpdateWarehouse from './modules/WarehouseConfig/UpdateWarehouse/UpdateWarehouseContainer'
import ListWarehouseStaff from './modules/WarehouseConfig/ListWarehouseStaff/ListWarehouseStaffContainer'
import CreateWarehouseStaffContainer from './modules/WarehouseConfig/CreateWarehouseStaff/CreateWarehouseStaffContainer'
import CreateInventory from './modules/Inventory/CreateInventory/CreateInventoryContainer'
import InventoryScan from './modules/Inventory/InventoryScan/InventoryScanContainer'
import ListBag from './modules/Bag/ListBag/ListBagContainer'
import ListProperty from './modules/Property/ListProperty/ListPropertyContainer'
import CreateProperty from './modules/Property/CreateProperty/CreatePropertyContainer'
import UpdateProperty from './modules/Property/UpdateProperty/UpdatePropertyContainer'
import SettingGeneral from './modules/Setting/General/SettingGeneralContainer'
/*Html*/
import PackageH from './modules/HtmlCss/Package'
import EditLinkPackageH from './modules/HtmlCss/Package/EditLinkPackage'
import LinkPackageH from './modules/HtmlCss/Package/LinkPackage'
import ViewUnLinkPackageH from './modules/HtmlCss/Package/ViewUnlLinkPackage'
import UnLinkPackageH from './modules/HtmlCss/Package/UnLinkPackage'
import ListPackageH from './modules/HtmlCss/Package/ListPackage'
import DetailPackageH from './modules/HtmlCss/Package/DetailPackage'
import EditDetailPackageH from './modules/HtmlCss/Package/EditDetailPackage'
import ScanEmptyH from './modules/HtmlCss/Package/ScanEmpty'
import BarcodeScanH from './modules/HtmlCss/BarcodeScan'
import HistoryBarcodeScanH from './modules/HtmlCss/BarcodeScan/HistoryScanCode'
import ListBagH from './modules/HtmlCss/Bag/ListBag/ListBagContainer'
import ListBagNeedPackH from './modules/HtmlCss/Bag/ListBagNeedPack/ListBagNeedPackContainer'
import CreatBagH from './modules/HtmlCss/Bag/CreateBag/CreateBagContainer'
import ViewBagH from './modules/HtmlCss/Bag/ViewBag/ViewBagContainer'
import BagPackH from './modules/HtmlCss/Bag/BagPack/BagPackContainer'
import DetailBagH from './modules/HtmlCss/Bag/DetailBag/DetailBagContainer'
import InventoryH from './modules/HtmlCss/Inventory/ListInventory/ListInventoryContainer'
import InventoryHistoryH from './modules/HtmlCss/Inventory/InventoryHistory/InventoryHistoryContainer'
import CrossCheckInventoryH from './modules/HtmlCss/Inventory/CrossCheck/CrossCheckContainer'
import InventoryDetailH from './modules/HtmlCss/Inventory/InventoryDetail/InventoryDetailContainer'
import InventoryScanH from './modules/HtmlCss/Inventory/InventoryScan/InventoryScanContainer'
import ScanExport from './modules/HtmlCss/ScanExport/ScanExportContainer'
import ListDeliveryNoteH from './modules/HtmlCss/ListDeliveryNote/ListDeliveryNoteContainer'
import DeliveryNoteDetailH from './modules/HtmlCss/DeliveryNoteDetail/DeliveryNoteDetailContainer'
import RequestDeliveryNoteH from './modules/HtmlCss/RequestDeliveryNote/RequestDeliveryNoteContainer'
import RequestDeliveryNoteDetailH from './modules/HtmlCss/RequestDeliveryNoteDetail/RequestDeliveryNoteDetailContainer'
import CheckPackageH from './modules/HtmlCss/CheckPackage/CheckPackageContainer'
import ListHistoryCheckPackageH from './modules/HtmlCss/HistoryCheckPackage/HistoryCheckPackageContainer'
import ConfigContainerH from './modules/HtmlCss/Configs/ConfigContainer'
/*End*/
import commonMiddleware from './system/middlewares/commonMiddleware'
import i18n from './system/i18n'
import { router } from './system/routing'
import CreateDeliveryNoteContainer from './modules/DeliveryNote/Create/CreateContainer'
import UpdateDeliveryNote from './modules/DeliveryNote/UpdateDeliveryNote/UpdateDeliveryNoteContainer'
import DeliveryNoteDetail from './modules/DeliveryNote/DetailDeliveryNote/DeliveryNoteDetailContainer'
import CreateDomesticShippingOrder from './modules/DeliveryNote/CreateDomesticShippingOrder/CreateDomesticShippingOrderContainer'
import ListDeliveryNote from './modules/DeliveryNote/ListDeliveryNote/ListDeliveryNoteContainer'
import DetailDeliveryNotePrintTestContainer from './modules/DeliveryNote/DeliveryNotePrint/DetailDeliveryNotePrintTestContainer'
import DeliveryNoteReturnList from './modules/DeliveryNote/Return/List/ListContainer'
import CreateDeliveryNoteReturn from './modules/DeliveryNote/Return/Create/CreateContainer'
import DeliveryNoteReturnDetail from './modules/DeliveryNote/Return/Detail/DetailContainer'
import EditDeliveryNoteReturn from './modules/DeliveryNote/Return/Edit/EditContainer'
import ListDeliveryNoteTransportWarehouse from './modules/DeliveryNote/TransportWarehouse/List/ListContainer'
import CreateDeliveryNoteTransportWarehouse from './modules/DeliveryNote/TransportWarehouse/Create/CreateContainer'
import UpdateDeliveryNoteTransportWarehouse from './modules/DeliveryNote/TransportWarehouse/Update/UpdateContainer'
import DeliveryNoteTransportWarehouseDetail from './modules/DeliveryNote/TransportWarehouse/Detail/DetailContainer'
import ListDeliveryRequest from './modules/DeliveryRequest/ListDeliveryRequest/ListDeliveryRequestContainer'
import ListBagSizeContainer from './modules/BagSize/ListBagSize/ListBagSizeContainer'
import CreateBagSizeContainer from './modules/BagSize/CreateBagSize/CreateBagSizeContainer'
import EditBagSizeContainer from './modules/BagSize/EditBagSize/EditBagSizeContainer'
import DetailBagSizeContainer from './modules/BagSize/DetailBagSize/DetailBagSizeContainer'
import ListShippingPartnerContainer from './modules/ShippingPartner/ListShippingPartner/ListShippingPartnerContainer'
import CreateShippingPartnerContainer from './modules/ShippingPartner/CreateShippingPartner/CreateShippingPartnerContainer'
import EditShipingPartnerContainer from './modules/ShippingPartner/EditShippingPartner/EditShipingPartnerContainer'
import ListShippingUserContainer from './modules/ShippingUser/List'
import CreateShippingUserContainer from './modules/ShippingUser/Create'
import EditShippingUserContainer from './modules/ShippingUser/Edit'
import TemplateBagListContainer from './modules/TemplateBag/List/TemplateBagListContainer'
import TemplateBagCreateContainer from './modules/TemplateBag/Create/TemplateBagCreateContainer'
import TemplateBagEditContainer from './modules/TemplateBag/Edit/TemplateBagEditContainer'
import InventoryProcessContainer from './modules/Inventory/Process/InventoryProcessContainer'
import InventoryHistoryContainer from './modules/Inventory/ListInventory/ListInventoryContainer'
import CanCreateDeliveryNoteMiddleware from './modules/DeliveryNote/middleware/CanCreateDeliveryNoteMiddleware'
import CanUpdateDeliveryNoteMiddleware from './modules/DeliveryNote/middleware/CanUpdateDeliveryNoteMiddleware'
import EditWarehouseStaffContainer from './modules/WarehouseConfig/EditWarehouseStaff/EditWarehouseStaffContainer'
import Notes from './modules/Notes/NotesPage/NotesPageContainer'
import CheckExistBag from './modules/Bag/middleware/CheckExistBag'
import PrintOfficialPackageStampTest from './modules/Common/PrintStamp/components/PrintOfficialPackageStampTest'
import PrintPackageUnOfficialStampTest from './modules/Common/PrintStamp/components/PrintPackageUnOfficialStampTest'
import PrintBagStampTest from './modules/Common/PrintStamp/components/PrintBagStampTest'
import SplitPackage from './modules/Package/SplitPackage'
import BagAreaContainer from './modules/Setting/BagArea/BagAreaContainer'
import UploadPackageList from './modules/Package/UploadPackageList/UploadPackageListContainer'
import NoticeStages from './modules/Setting/NoticeStages/NoticeStagesContainer'
import ShippingFeeListContainer from './modules/ShippingFee/List/ShippingFeeListContainer'
import StorageFees from './modules/Setting/StorageFees/StorageFeesContainer'
import Statistics from './modules/Statistics/StatisticsContainer'
import PrintBatchStampTest from './modules/Common/PrintStamp/components/PrintBatchStampTest'
import ExpeditedProcessing from './modules/ExpeditedProcessing/ExpeditedProcessing'
import CheckSettingExpedited from './modules/ExpeditedProcessing/middleware/CheckSettingExpedited'
import TemplateBagLastmileListContainer from './modules/TemplateBagLastmile/List/TemplateBagLastmileListContainer'
import TemplateLastmileCreate from './modules/TemplateBagLastmile/Create'
import TemplateBagEditLastmile from './modules/TemplateBagLastmile/Edit'
import WaitingDeliveryList from './modules/Package/WaitingDelivery/WaitingDeliveryList'
import ScanSplitPackage from './modules/Package/ScanSplitPackage'
import AutoSplitPackage from './modules/Package/AutoSplitPackage'
import TemplateDefault from './modules/Common/PrintStamp/components/ShippingBagStamp/TemplateDefault'
import AgencyFinance from './modules/Agencies/AgencyFinanceContainer'
import CreateDocument from './modules/ReturnGoods/CreateDocument'
import DetailDocument from './modules/ReturnGoods/DetailDocument'
import ReturnGoodsList from './modules/ReturnGoods/List'
import UpdateDocument from './modules/ReturnGoods/UpdateDocument'
import UploadCommentImages from './modules/Package/UploadCommentImages/components'
import UploadChatImagesMiddleware from './modules/Package/middlewares/UploadCommentImagesMiddleware'
import ScanToStowing from './modules/Warehouse/ScanToStowing'
import LastmileOrderListContainer from './modules/LastmileOrder/LastmileOrderListContainer'
import BaggingRequestList from './modules/Bag/BagRequest/List'
import BagRequestDetail from './modules/Bag/BagRequest/Detail'
import CreatePackageConsignmentContainer
    from './modules/Package/CreatePackageConsignment/CreatePackageConsignmentContainer'
import CheckSetting from './modules/Package/CreatePackageConsignment/middleware/CheckSetting'

export default {
    routes: [
        {
            name: '403',
            path: '/403',
            exact: true,
            component: Page403,
        },
        {
            name: 'login',
            path: '/login',
            exact: true,
            component: Login,
        },
        {
            name: 'login.callback',
            path: '/login/callback',
            exact: true,
            component: LoginCallback,
        },
        {
            middleware: [authenticate, loadAuthUser],
            name: 'deliveryNote.print.test',
            path: '/delivery-notes/detail/:id/print',
            exact: true,
            component: DetailDeliveryNotePrintTestContainer,
        },
        {
            middleware: [authenticate, loadAuthUser],
            name: 'print-package-official-stamp',
            path: '/print-package-official-stamp/:code',
            exact: true,
            component: PrintOfficialPackageStampTest,
        },
        {
            middleware: [authenticate, loadAuthUser],
            name: 'print-package-unofficial-stamp',
            path: '/print-package-unofficial-stamp/:code',
            exact: true,
            component: PrintPackageUnOfficialStampTest,
        },
        {
            middleware: [authenticate, loadAuthUser],
            name: 'print-bag-stamp',
            path: '/print-bag-stamp/:code',
            exact: true,
            component: PrintBagStampTest,
        },
        {
            name: 'upload-damaged-images',
            path: '/packages/upload-damaged-images',
            exact: true,
            component: UploadDamageImages,
            layout: null,
            middleware: [UploadDamagedImagesMiddleware, authenticate, loadAuthUser],
        },
        {
            name: 'upload-comment-images',
            path: '/packages/upload-comment-images',
            exact: true,
            component: UploadCommentImages,
            layout: null,
            middleware: [UploadChatImagesMiddleware, authenticate, loadAuthUser],
        },
        {
            middleware: [authenticate, loadAuthUser],
            name: 'print-batch-stamp',
            path: '/print-batch-stamp',
            exact: true,
            component: PrintBatchStampTest,
        },
        {
            group: {
                layout: DefaultLayout,
                middleware: [authenticate, loadAuthUser, loadSetting],
                routes: [
                    {
                        name: 'home',
                        path: '/',
                        exact: true,
                        component: Home,
                    },
                    {
                        group: {
                            name: 'packages.',
                            prefix: '/packages',
                            routes: [
                                {
                                    name: 'create',
                                    path: 'create',
                                    exact: true,
                                    component: CreatePackage,
                                    middleware: [can(PERMISSION.PACKAGE_CREATE_BY_BARCODE), loadSetting],
                                },
                                {
                                    name: 'create_consignment',
                                    path: 'consignment/create',
                                    exact: true,
                                    component: CreatePackageConsignmentContainer,
                                    middleware: [can(PERMISSION.PACKAGE_CREATE_BY_BARCODE), loadSetting, CheckSetting],
                                },
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: ListPackage,
                                    middleware: [can(PERMISSION.PACKAGE_VIEW), loadSetting],
                                },
                                {
                                    name: 'link_stamp',
                                    path: 'stamp/:id/:code',
                                    exact: true,
                                    component: LinkStamp,
                                    layout: null,
                                    middleware: [can(PERMISSION.PACKAGE_VIEW)],
                                },
                                {
                                    name: 'detail',
                                    path: 'detail/:id',
                                    exact: true,
                                    component: PackageDetail,
                                    middleware: [can(PERMISSION.PACKAGE_VIEW), loadSetting],
                                },
                                {
                                    name: 'checking',
                                    path: 'checking',
                                    exact: true,
                                    component: CheckingPackage,
                                    middleware: [can(PERMISSION.PACKAGE_ITEM_CHECKING)],
                                },
                                {
                                    name: 'split_package',
                                    path: 'split-package/:id',
                                    exact: true,
                                    component: SplitPackage,
                                    middleware: [can(PERMISSION.PACKAGE_VIEW), loadSetting],
                                },
                                {
                                    name: 'upload_package_list',
                                    path: '/upload',
                                    exact: true,
                                    component: UploadPackageList,
                                    middleware: [can(PERMISSION.PACKAGE_CHANGE_TRANSPORT_STATUS)],
                                },
                                {
                                    name: 'expedited_processing',
                                    path: '/expedited-processing',
                                    exact: true,
                                    component: ExpeditedProcessing,

                                    middleware: [can(PERMISSION.PACKAGE_EXPEDITED_PROCESSING), loadSetting, CheckSettingExpedited],
                                },
                                {
                                    name: 'waiting_delivery',
                                    path: '/waiting-delivery',
                                    exact: true,
                                    component: WaitingDeliveryList,
                                    middleware: [can(PERMISSION.DELIVERY_REQUEST_VIEW_PROCESSING)],
                                },
                                {
                                    name: 'scan_split_package',
                                    path: '/scan-split-package',
                                    exact: true,
                                    component: ScanSplitPackage,
                                    middleware: [can(PERMISSION.PACKAGE_CREATE_BY_BARCODE)],
                                },
                                {
                                    name: 'auto_split_package',
                                    path: 'auto-split-package/:id',
                                    exact: true,
                                    component: AutoSplitPackage,
                                    middleware: [can(PERMISSION.PACKAGE_VIEW)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'bags.',
                            prefix: '/bags',
                            routes: [
                                {
                                    name: 'create',
                                    path: 'create',
                                    exact: true,
                                    component: CreateBag,
                                    middleware: [can(PERMISSION.BAG_CREATE), loadSetting],
                                },
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: ListBag,
                                    middleware: [can(PERMISSION.BAG_VIEW)],
                                },
                                {
                                    name: 'request-list',
                                    path: '/request-list',
                                    exact: true,
                                    component: BaggingRequestList,
                                    middleware: [can(PERMISSION.PACKAGING_REQUEST_VIEW)],
                                },
                                {
                                    name: 'request-detail',
                                    path: '/request-detail/:id',
                                    exact: true,
                                    component: BagRequestDetail,
                                    middleware: [can(PERMISSION.PACKAGING_REQUEST_VIEW_DETAIL)],
                                },
                                {
                                    name: 'detail',
                                    path: 'detail/:id',
                                    exact: true,
                                    component: BagDetail,
                                    middleware: [can(PERMISSION.BAG_VIEW_DETAIL), loadSetting],
                                },
                                {
                                    name: 'packing',
                                    path: '/packing/:id',
                                    exact: true,
                                    component: BagPackingContainer,
                                    middleware: [canAny([PERMISSION.BAG_PACKING, PERMISSION.BAG_PACKING_ALL]), loadSetting, CheckExistBag],
                                },
                                {
                                    name: 'packing-lastmile',
                                    path: '/packing-lastmile/:id',
                                    exact: true,
                                    component: BagPackingLastmileContainer,
                                    middleware: [can(PERMISSION.BAG_LASTMILE_PACKING), loadSetting],
                                },
                                {
                                    name: 'list_bag_pack',
                                    path: '/list-pack',
                                    exact: true,
                                    component: ListBag,
                                    middleware: [can(PERMISSION.BAG_VIEW_PACKING)],
                                },
                                {
                                    name: 'manifest',
                                    path: '/manifest',
                                    exact: true,
                                    component: Manifest,
                                    middleware: [can(PERMISSION.IMPORT_MANIFEST)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'bags-lastmile.',
                            prefix: '/bags-lastmile',
                            routes: [
                                {
                                    name: 'create',
                                    path: 'create',
                                    exact: true,
                                    component: CreateBagLastmile,
                                    middleware: [can(PERMISSION.BAG_LASTMILE_CREATE), loadSetting],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'barcode.',
                            prefix: '/barcode',
                            routes: [
                                {
                                    name: 'scan',
                                    path: 'scan',
                                    exact: true,
                                    component: ScanBarcode,
                                    middleware: [can(PERMISSION.BARCODE_SCAN), loadSetting],
                                },
                                {
                                    name: 'scanning-history',
                                    path: 'scanning-history',
                                    exact: true,
                                    component: ScanningHistory,
                                    middleware: [can(PERMISSION.BARCODE_VIEW)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'return-goods.',
                            prefix: '/return-goods',
                            routes: [
                                {
                                    name: 'create',
                                    path: '/create',
                                    exact: true,
                                    component: CreateDocument,
                                    middleware: [can(PERMISSION.RETURN_NOTE_MANAGE), loadSetting],
                                },
                                {
                                    name: 'detail',
                                    path: '/detail/:id',
                                    exact: true,
                                    component: DetailDocument,
                                    middleware: [can(PERMISSION.RETURN_NOTE_MANAGE)],
                                },
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: ReturnGoodsList,
                                    middleware: [can(PERMISSION.RETURN_NOTE_MANAGE)],
                                },
                                {
                                    name: 'edit',
                                    path: '/edit/:id',
                                    exact: true,
                                    component: UpdateDocument,
                                    middleware: [can(PERMISSION.RETURN_NOTE_MANAGE)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'delivery-notes.',
                            prefix: '/delivery-notes',
                            routes: [
                                {
                                    name: '',
                                    path: '/',
                                    exact: true,
                                    component: () => <Redirect to="/delivery-notes/customer" />,
                                },
                                {
                                    group: {
                                        name: 'customer.',
                                        prefix: '/customer',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListDeliveryNote,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_VIEW_LISTING)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateDeliveryNoteContainer,
                                                middleware: [CanCreateDeliveryNoteMiddleware, loadSetting],
                                            },
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: UpdateDeliveryNote,
                                                middleware: [CanUpdateDeliveryNoteMiddleware, loadSetting],
                                            },
                                            {
                                                name: 'detail',
                                                path: ':id',
                                                exact: true,
                                                component: DeliveryNoteDetail,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_VIEW_DETAIL)],
                                            },
                                            {
                                                name: 'domestic-shipping-orders',
                                                path: 'domestic-shipping-orders/:id',
                                                exact: true,
                                                component: CreateDomesticShippingOrder,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_VIEW_DETAIL)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'return.',
                                        prefix: '/return',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: DeliveryNoteReturnList,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_RETURN_MANAGE)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateDeliveryNoteReturn,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_RETURN_MANAGE)],
                                            },
                                            {
                                                name: 'detail',
                                                path: ':id',
                                                exact: true,
                                                component: DeliveryNoteReturnDetail,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_RETURN_MANAGE)],
                                            },
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: EditDeliveryNoteReturn,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_RETURN_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'transport-warehouse.',
                                        prefix: '/transport-warehouse',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListDeliveryNoteTransportWarehouse,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateDeliveryNoteTransportWarehouse,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE)],
                                            },
                                            {
                                                name: 'detail',
                                                path: ':id',
                                                exact: true,
                                                component: DeliveryNoteTransportWarehouseDetail,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE)],
                                            },
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: UpdateDeliveryNoteTransportWarehouse,
                                                middleware: [can(PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        name: 'statistics',
                        path: '/statistics',
                        exact: true,
                        component: Statistics,
                        middleware: [can(PERMISSION.STATISTICS_MANAGE)],
                    },
                    {
                        name: 'agency_finance',
                        path: '/agency_finance',
                        exact: true,
                        component: AgencyFinance,
                        middleware: [can(PERMISSION.FINANCE_ADJUST_VIEW)],
                    },
                    {
                        group: {
                            layout: ConfigLayout,
                            name: 'config.',
                            prefix: '/config',
                            routes: [
                                {
                                    group: {
                                        name: 'warehouse.',
                                        prefix: '/warehouse',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListWarehouse,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateWarehouse,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE)],
                                            },
                                            {
                                                name: 'update',
                                                path: 'update/:id',
                                                exact: true,
                                                component: UpdateWarehouse,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'warehouse-staff.',
                                        prefix: '/warehouse-staff',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListWarehouseStaff,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE_STAFF)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateWarehouseStaffContainer,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE_STAFF)],
                                            },
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: EditWarehouseStaffContainer,
                                                middleware: [can(PERMISSION.WAREHOUSES_MANAGE_STAFF)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'bag-sizes.',
                                        prefix: '/bag-sizes',
                                        routes: [
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                exact: true,
                                                component: DetailBagSizeContainer,
                                                middleware: [can(PERMISSION.CONFIG_BAG_SIZE)],
                                            },
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: EditBagSizeContainer,
                                                middleware: [can(PERMISSION.CONFIG_BAG_SIZE)],
                                            },
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListBagSizeContainer,
                                                middleware: [can(PERMISSION.CONFIG_BAG_SIZE)],
                                            },
                                            {
                                                name: 'create',
                                                path: 'create',
                                                exact: true,
                                                component: CreateBagSizeContainer,
                                                middleware: [can(PERMISSION.CONFIG_BAG_SIZE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'property.',
                                        prefix: '/property',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListProperty,
                                                middleware: [can(PERMISSION.PROPERTIES_MANAGE)],
                                            },
                                            {
                                                name: 'create',
                                                path: '/create',
                                                exact: true,
                                                component: CreateProperty,
                                                middleware: [can(PERMISSION.PROPERTIES_MANAGE)],
                                            },
                                            {
                                                name: 'update',
                                                path: '/update/:id',
                                                exact: true,
                                                component: UpdateProperty,
                                                middleware: [can(PERMISSION.PROPERTIES_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'createShippingUser',
                                    path: '/create-shipping-user',
                                    exact: true,
                                    component: CreateShippingUserContainer,
                                    middleware: [can(PERMISSION.DELIVERY_AGENT_MANAGE)],
                                },
                                {
                                    group: {
                                        name: 'shipping-fees.',
                                        prefix: '/shipping-fees',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/:type?',
                                                exact: true,
                                                component: ShippingFeeListContainer,
                                            },
                                        ],
                                        middleware: [can(PERMISSION.SHIPPING_FEE_MANAGE)],
                                    },
                                },
                                {
                                    name: 'settingGeneral',
                                    path: '/general',
                                    exact: true,
                                    component: SettingGeneral,
                                    middleware: [can(PERMISSION.CONFIG_GENERAL)],
                                },
                                {
                                    group: {
                                        name: 'shipping-partners.',
                                        prefix: '/shipping-partners',
                                        routes: [
                                            {
                                                name: 'create',
                                                path: '/create/:type',
                                                exact: true,
                                                component: CreateShippingPartnerContainer,
                                                middleware: [can(PERMISSION.DELIVERY_PARTNER_MANAGE)],
                                            },
                                            {
                                                path: '/create',
                                                exact: true,
                                                component: () => <Redirect to="/config/shipping-partners/create/international" />,
                                            },
                                            {
                                                name: 'edit',
                                                path: '/:type/edit/:id',
                                                exact: true,
                                                component: EditShipingPartnerContainer,
                                                middleware: [CanManageDeliveryPartnerMiddleware],
                                            },

                                            {
                                                name: 'list',
                                                path: '/:type',
                                                exact: true,
                                                component: ListShippingPartnerContainer,
                                                middleware: [CanManageDeliveryPartnerMiddleware],
                                            },
                                            {
                                                path: '/',
                                                exact: true,
                                                component: () => {
                                                    if (authService.can(permissions.DELIVERY_PARTNER_MANAGE)) {
                                                        return <Redirect to="/config/shipping-partners/international" />
                                                    }

                                                    if (authService.can(permissions.DOMESTIC_DELIVERY_PARTNER_MANAGE)) {
                                                        return <Redirect to="/config/shipping-partners/domestic" />
                                                    }

                                                    return <Redirect to="/403" />
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'shipping-users',
                                        prefix: '/shipping-users',
                                        routes: [
                                            {
                                                name: 'edit',
                                                path: 'edit/:id',
                                                exact: true,
                                                component: EditShippingUserContainer,
                                                middleware: [can(PERMISSION.DELIVERY_AGENT_MANAGE)],
                                            },
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: ListShippingUserContainer,
                                                middleware: [can(PERMISSION.DELIVERY_AGENT_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'template-bags.',
                                        prefix: '/template-bags',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: TemplateBagListContainer,
                                                middleware: [can(PERMISSION.CONFIG_SAMPLE_BAG)],
                                            },
                                            {
                                                name: 'create',
                                                path: '/create',
                                                exact: true,
                                                component: TemplateBagCreateContainer,
                                                middleware: [can(PERMISSION.CONFIG_SAMPLE_BAG)],
                                            },
                                            {
                                                name: 'edit',
                                                path: '/:id/edit',
                                                exact: true,
                                                component: TemplateBagEditContainer,
                                                middleware: [can(PERMISSION.CONFIG_SAMPLE_BAG)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'template-bags-lastmile.',
                                        prefix: '/template-bags-lastmile',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: TemplateBagLastmileListContainer,
                                                middleware: [can(PERMISSION.LASTMILE_BAG_TEMPLATE_MANAGE)],
                                            },
                                            {
                                                name: 'create',
                                                path: '/create',
                                                exact: true,
                                                component: TemplateLastmileCreate,
                                                middleware: [can(PERMISSION.LASTMILE_BAG_TEMPLATE_MANAGE)],
                                            },
                                            {
                                                name: 'edit',
                                                path: '/:id/edit',
                                                exact: true,
                                                component: TemplateBagEditLastmile,
                                                middleware: [can(PERMISSION.LASTMILE_BAG_TEMPLATE_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'bag-area.',
                                        prefix: '/bag-areas',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                exact: true,
                                                component: BagAreaContainer,
                                                middleware: [can(PERMISSION.BAG_AREA_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'notice-stages.',
                                        prefix: '/notice-stages',
                                        routes: [
                                            {
                                                name: 'notice-stages',
                                                path: '/',
                                                exact: true,
                                                component: NoticeStages,
                                                middleware: [can(PERMISSION.NOTICE_STAGE_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'storage-fees.',
                                        prefix: '/storage-fees',
                                        routes: [
                                            {
                                                name: 'storage-fees',
                                                path: '/',
                                                exact: true,
                                                component: () => <Redirect to="/config/storage-fees/package" />,
                                                middleware: [can(PERMISSION.STORAGE_FEE_MANAGE)],
                                            },
                                            {
                                                name: 'storage-fees',
                                                path: '/:type',
                                                exact: true,
                                                component: StorageFees,
                                                middleware: [can(PERMISSION.STORAGE_FEE_MANAGE)],
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'delivery-request.',
                            prefix: '/delivery-requests',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: ListDeliveryRequest,
                                    middleware: [can(PERMISSION.DELIVERY_REQUEST_VIEW_LISTING)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'inventory.',
                            prefix: '/inventory',
                            routes: [
                                {
                                    name: 'create',
                                    path: 'create',
                                    exact: true,
                                    component: CreateInventory,
                                    middleware: [canAny([PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL])],
                                },
                                {
                                    name: 'scan',
                                    path: 'scan/:id',
                                    exact: true,
                                    component: InventoryScan,
                                    middleware: [canAny([PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL])],
                                },
                                {
                                    name: 'process',
                                    path: '/:id/process',
                                    exact: true,
                                    component: InventoryProcessContainer,
                                    middleware: [canAny([PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL])],
                                },
                                {
                                    name: 'history',
                                    path: '/',
                                    exact: true,
                                    component: InventoryHistoryContainer,
                                    middleware: [canAny([PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'notes.',
                            prefix: '/notes',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: Notes,
                                    middleware: [canAny([PERMISSION.ATTENTION_MANAGE, PERMISSION.ATTENTION_CUSTOMER_MANAGE])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'warehouse.',
                            prefix: '/warehouse',
                            routes: [
                                {
                                    name: 'scan-to-stowing',
                                    path: '/scan-to-stowing',
                                    exact: true,
                                    component: ScanToStowing,
                                    middleware: [can(PERMISSION.PACKAGE_STORE_PACKAGE)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'lastmile-order.',
                            prefix: '/lastmile-order',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    exact: true,
                                    component: LastmileOrderListContainer,
                                    middleware: [can(PERMISSION.LASTMILE_ORDER_VIEW)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'html.',
                            prefix: '/html',
                            routes: [
                                {
                                    name: 'scan-empty',
                                    path: 'scan-empty',
                                    exact: true,
                                    component: ScanEmptyH,
                                },
                                {
                                    name: 'package',
                                    path: 'package',
                                    exact: true,
                                    component: PackageH,
                                },
                                {
                                    name: 'edit-link-package',
                                    path: '/edit-link-package',
                                    exact: true,
                                    component: EditLinkPackageH,
                                },
                                {
                                    name: 'link-package',
                                    path: '/link-package',
                                    exact: true,
                                    component: LinkPackageH,
                                },
                                {
                                    name: 'view-unlink-package',
                                    path: '/view-unlink-package',
                                    exact: true,
                                    component: ViewUnLinkPackageH,
                                },
                                {
                                    name: 'unlink-package',
                                    path: '/unlink-package',
                                    exact: true,
                                    component: UnLinkPackageH,
                                },
                                {
                                    name: 'barcode-scan',
                                    path: '/barcode-scan',
                                    exact: true,
                                    component: BarcodeScanH,
                                },
                                {
                                    name: 'history-barcode-scan',
                                    path: '/history-barcode-scan',
                                    exact: true,
                                    component: HistoryBarcodeScanH,
                                },
                                {
                                    name: 'list-package',
                                    path: '/list-package',
                                    exact: true,
                                    component: ListPackageH,
                                },
                                {
                                    name: 'detail-package',
                                    path: '/detail-package',
                                    exact: true,
                                    component: DetailPackageH,
                                },
                                {
                                    name: 'edit-detail-package',
                                    path: '/edit-detail-package',
                                    exact: true,
                                    component: EditDetailPackageH,
                                },
                                {
                                    name: 'list-bag',
                                    path: '/list-bag',
                                    exact: true,
                                    component: ListBagH,
                                },
                                {
                                    name: 'list-bag-pack',
                                    path: '/list-bag-pack',
                                    exact: true,
                                    component: ListBagNeedPackH,
                                },
                                {
                                    name: 'create-bag',
                                    path: '/create-bag',
                                    exact: true,
                                    component: CreatBagH,
                                },
                                {
                                    name: 'view-bag',
                                    path: '/view-bag',
                                    exact: true,
                                    component: ViewBagH,
                                },
                                {
                                    name: 'bag-pack',
                                    path: '/bag-pack',
                                    exact: true,
                                    component: BagPackH,
                                },
                                {
                                    name: 'detail-bag',
                                    path: '/detail-bag',
                                    exact: true,
                                    component: DetailBagH,
                                },
                                {
                                    name: 'inventory',
                                    path: '/inventory',
                                    exact: true,
                                    component: InventoryH,
                                },
                                {
                                    name: 'inventory-history',
                                    path: '/inventory-history',
                                    exact: true,
                                    component: InventoryHistoryH,
                                },
                                {
                                    name: 'inventory-cross-check',
                                    path: '/inventory-cross-check',
                                    exact: true,
                                    component: CrossCheckInventoryH,
                                },
                                {
                                    name: 'inventory-detail',
                                    path: '/inventory-detail',
                                    exact: true,
                                    component: InventoryDetailH,
                                },
                                {
                                    name: 'inventory-scan',
                                    path: '/inventory-scan',
                                    exact: true,
                                    component: InventoryScanH,
                                },
                                {
                                    name: 'scan-export',
                                    path: '/scan-export',
                                    exact: true,
                                    component: ScanExport,
                                },
                                {
                                    name: 'list-delivery-note',
                                    path: '/list-delivery-note',
                                    exact: true,
                                    component: ListDeliveryNoteH,
                                },
                                {
                                    name: 'delivery-note-detail',
                                    path: 'delivery-note-detail',
                                    exact: true,
                                    component: DeliveryNoteDetailH,
                                },
                                {
                                    name: 'request-delivery-note',
                                    path: 'request-delivery-note',
                                    exact: true,
                                    component: RequestDeliveryNoteH,
                                },
                                {
                                    name: 'request-delivery-note-detail',
                                    path: '/request-delivery-note-detail',
                                    exact: true,
                                    component: RequestDeliveryNoteDetailH,
                                },
                                {
                                    name: 'check-package',
                                    path: '/check-package',
                                    exact: true,
                                    component: CheckPackageH,
                                },
                                {
                                    name: 'list-history-check-package',
                                    path: '/list-history-check-package',
                                    exact: true,
                                    component: ListHistoryCheckPackageH,
                                },
                                {
                                    name: 'configs',
                                    path: '/configs',
                                    exact: true,
                                    component: ConfigContainerH,
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            name: 'Page403',
            path: '/403',
            exact: true,
            component: Page403,
        },
    ],
    defaultRoute: {
        component: Page404,
    },
    middleware: [(payload, next) => commonMiddleware(payload, next, router, i18n)],
}
