import { Button, Layout } from 'antd'
import { t } from '../../../../system/i18n'
import React from 'react'
import {  withTranslation } from 'react-i18next'

const { Footer } = Layout;

const Action = ({onSubmit,onReset,loading}) => {
    return (
        <Footer className={'consignment-footer'}>
            <div className="a-flex">
                    <Button
                        className="a-btn a-btn--primary mr-2 a-btn--save-export _btn-save"
                        size="large"
                        onClick={onSubmit}
                        loading={loading}
                    >
                        {t('create_order')}
                    </Button>
                    <Button
                        className="a-btn a-btn--save-export _btn-save"
                        size="large"
                        type={'ghost'}
                        onClick={onReset}
                    >
                        {t('btn.clear')}
                    </Button>
            </div>
        </Footer>
    )
}

export default withTranslation()(Action)