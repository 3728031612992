import React from 'react';
import ReactDOM from 'react-dom';
import StoreProvider from './system/store/StoreProvider';
import I18nProvider from './system/i18n/I18nProvider';
import RoutingProvider from './system/routing/RoutingProvider';

import './resources/scss/main.scss';
import ReactQueryProvider from './system/react-query/ReactQueryProvider'


ReactDOM.render(
    <ReactQueryProvider>
        <StoreProvider>
            <I18nProvider>
                <RoutingProvider/>
            </I18nProvider>
        </StoreProvider>
    </ReactQueryProvider>,
    document.getElementById('root')
);