import { getVar } from '../../../../system/support/helpers'
import { t } from '../../../../system/i18n'
import { Button, Icon, Input, Table, Tag } from 'antd'
import React, { useRef, useState } from 'react'

const TableNested = ({data}) => {
    const inputRef = useRef()
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={inputRef}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => inputRef.current.select());
            }
        },

    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const columns = [
        {
            title: t('package_consignment:column.package_code'),
            dataIndex: 'code',
            key: 'code',
            render: (code) => {
                return <a target={'_blank'} href={`/packages/detail/${code}`}>
                    {code}
                </a>
            },
            ...getColumnSearchProps('code')
        },
        {
            title: t('package_consignment:column.status'),
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let tagColor = "blue"
                if (status === "success") {
                    tagColor = "green"
                }
                if(status === "pending") {
                    tagColor = "orange"
                }
                return <Tag color={tagColor}>{status}</Tag>
            }
        },
        {
            title: t('package_consignment:column.error'),
            dataIndex: 'error',
            key: 'error',
            render: (error) => {
                return error || ''
            }
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={true}
        />
    )

}

export default TableNested;