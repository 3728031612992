import React from 'react';
import { Layout } from 'antd';
import { get } from 'lodash';

import { DELIVERY_OBJECT } from '../../../constants';

import Detail from './../../Detail/components';
import FormDeliveryNote from './../../../Create/components/FormDeliveryNote';
import FormDeliveryObject from './../../../Create/components/FormDeliveryObject';

class Edit extends Detail {
    getInput = () => {
        const { deliveryNoteReturn } = this.props;

        return {
            id_warehouse: get(deliveryNoteReturn, 'warehouse.id'),
            id_shipping_return_partner: get(deliveryNoteReturn, 'shipping_return_partner.id')
        };
    };

    render() {
        const { deliveryNoteReturn, loading } = this.props;
        const input = this.getInput();
        return (
            <Layout>
                {loading ? (
                    <div className="a-loading-page__icon" />
                ) : (
                    <>
                        <FormDeliveryNote
                            canChangeInput
                            deliveryNote={deliveryNoteReturn}
                            input={input}
                            scanBarcodeByCustomer={!!input.object}
                            type={DELIVERY_OBJECT.RETURN}
                            onChange={this.handleChangeInput}
                        />

                        <FormDeliveryObject
                            input={input}
                            object={DELIVERY_OBJECT.RETURN}
                        />
                    </>
                )}
            </Layout>
        );
    }
}

export default Edit;
