import {CHECKING_PACKAGE} from "./../Package/CheckingPackage/constants";
import {SCAN_BARCODE_FORM, TRANSPORT_WAREHOUSE} from "../ScanBarcode/ScanBarcodeForm/constants";
import {SCAN_BARCODE} from "../DeliveryNote/DeliveryNoteBarcode/constants";
import {CREATE_PACKAGE_BY_SCAN} from "../Bag/constants";
import {PACKING_PACKAGE} from "../Bag/BagPackingAction/constants";
import {SCAN_PACKAGE, SCAN_TRACKING_BILL} from "../Inventory/InventoryBarcode/constants";
import { PACKING_PACKAGE_LASTMILE } from "../Bag/BagPackingLastmile/constants";

export const CHANGE_STATUS_SOUND = 'CHANGE_STATUS_SOUND';
export const PLAY_SOUND = 'PLAY_SOUND';
export const SOUND_CONSTANT = {
    SUCCESS: 'SOUND_SCAN_SUCCESS',
    ERROR: 'SOUND_SCAN_ERROR',
    WARNING: 'SOUND_SCAN_WARNING',
    NORMAL: 'SOUND_SCAN_NORMAL',
    BEEP: 'BEEP',
    //Warning
    WRONG_WAREHOUSE: 'WRONG_WAREHOUSE', //Kiện nhầm kho
    PACKAGE_NOT_OF_CUSTOMER: 'PACKAGE_NOT_OF_CUSTOMER', //Kiện nhầm khách
    ALREADY_OTHER_BAG: 'ALREADY_OTHER_BAG', //Kiện nằm trong bao khác
    ALREADY_THIS_BAG: 'ALREADY_THIS_BAG', //Kiện quét nhiều lần
    ERROR_SCAN_PACKAGE: 'ERROR_SCAN_PACKAGE', // âm thanh chung
    WARNING_ERROR_SCAN_PACKAGE: 'WARNING_ERROR_SCAN_PACKAGE', // âm thanh chung
    SCAN_PACKAGE_SCANNED: 'SCAN_PACKAGE_SCANNED',// trùng mã kiện
    SCAN_BAG_SCANNED: 'SCAN_BAG_SCANNED',// trùng mã bao
    NETWORK_ERROR: 'NETWORK_ERROR', // lỗi mạng,
    ERROR_SCAN_PACKAGE_NOT_IN_DESTINATION_WAREHOUSE: 'ERROR_SCAN_PACKAGE_NOT_IN_DESTINATION_WAREHOUSE', // luu y kien sai kho dich
    ERROR_SCAN_BAG_NOT_IN_DESTINATION_WAREHOUSE: 'ERROR_SCAN_BAG_NOT_IN_DESTINATION_WAREHOUSE', //  luu y bao sai kho dich
    ATTENTION_ERROR_SCAN_BAG: 'ATTENTION_ERROR_SCAN_BAG', // kien sai kho dich
    ATTENTION_ERROR_SCAN_PACKAGE: 'ATTENTION_ERROR_SCAN_PACKAGE', // Bao sai kho dich
    ERROR_SCAN_BAG: 'ERROR_SCAN_BAG',
    WARNING_ERROR_SCAN_BAG: 'WARNING_ERROR_SCAN_BAG',
    PROPERTY_NOT_CONSISTENT: "PROPERTY_NOT_CONSISTENT", //đặc tính không đồng nhất
    ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED: 'ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED',
    ERROR_SCAN_BAG_NOT_VALID: "ERROR_SCAN_BAG_NOT_VALID",
    ERROR_WAREHOUSE_NOT_EXIST: "ERROR_WAREHOUSE_NOT_EXIST",
    ERROR_BARCODE_NOT_EXIST: "ERROR_BARCODE_NOT_EXIST",
    ERROR_SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE: "ERROR_SCAN_PACKAGE_IN_OUT_WRONG_WAREHOUSE",
    ERROR_SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE: "ERROR_SCAN_PACKAGE_IN_IN_WRONG_WAREHOUSE",
    ERROR_SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE: "ERROR_SCAN_PACKAGE_OUT_OUT_WRONG_WAREHOUSE",
    ERROR_SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE: "ERROR_SCAN_PACKAGE_IN_DUPLICATE_WAREHOUSE",
    ERROR_SCAN_BAG_MULTIPLE_COMPLETED: "ERROR_SCAN_BAG_MULTIPLE_COMPLETED",
    ERROR_SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE: "ERROR_SCAN_PACKAGE_OUT_DUPLICATE_WAREHOUSE",
    SERVICE_NOT_CONSISTENT: "SERVICE_NOT_CONSISTENT", //dịch vụ không đồng nhất
    ERROR_SCAN_PACKAGE_IN_SINGLE_BAG_COMPLETED: "ERROR_SCAN_PACKAGE_IN_SINGLE_BAG_COMPLETED", // Kiện nằm trong bao nguyên chưa tới đích
    SOUND_SCAN_ERROR: "SOUND_SCAN_ERROR",
    DIFF_WAREHOUSE_DESTINATION: "PACKAGE_WAREHOUSE_DESTINATION_IS_DIFFERENT_FROM_BAG_WAREHOUSE_DESTINATION",// Kho kiện khác kho của bao
    ATTENTION_DIFF_WAREHOUSE_DESTINATION: "ATTENTION_DIFF_WAREHOUSE_DESTINATION",// Kho kiện khác kho của bao
    DIFFERENT_SHIPPING_PARTNER: "BAG_SHIPPING_PARTNER_IS_DIFFERENT_FROM_PACKAGE_SHIPPING_PARTNER",
    ATTENTION_DIFFERENT_SHIPPING_PARTNER: "ATTENTION_DIFFERENT_SHIPPING_PARTNER",
    SCAN_PACKET_SCANNED: "SCAN_PACKET_SCANNED", // Mã xuất quét nhiều lần
    RESTRICT_ALERT: 'RESTRICT_ALERT', //đặc tính hạn chế
    // Error
    INPUT_INVALID: "INPUT_INVALID",
    BARCODE_NOT_EXIST: "BARCODE_NOT_EXIST",
    PACKAGE_NOT_FOUND: "PACKAGE_NOT_FOUND",
    BAG_NOT_FOUND: "BAG_NOT_FOUND",
    ERROR_SCAN_BAG_PACKING: "ERROR_SCAN_BAG_PACKING",
    ERROR_SCAN_PACKAGE_NOT_VALID: "ERROR_SCAN_PACKAGE_NOT_VALID",
    WARNING_SCAN_PACKAGE_NOT_IN_BAG: "WARNING_SCAN_PACKAGE_NOT_IN_BAG",
    WARNING_SCAN_PACKAGE_UNPROCESSED: 'WARNING_SCAN_PACKAGE_UNPROCESSED',

    ATTENTION: 'ATTENTION',
    BUBBLE_WRAPPING: 'BUBBLE_WRAPPING',
    CANCELLED: 'CANCELLED',
    CARGO_INSURANCE: 'CARGO_INSURANCE',
    CHECKED: 'CHECKED',
    COMMITMENT_TRANSPORT_TIME: 'COMMITMENT_TRANSPORT_TIME',
    DETACH_PACKAGE: 'DETACH_PACKAGE',
    DIRECT_DELIVERY: 'DIRECT_DELIVERY',
    DUPLICATE_PACKAGE: 'DUPLICATE_PACKAGE',
    ECO_SHIPPING: 'ECO_SHIPPING',
    ECOMMERCE_SHIPPING: 'ECOMMERCE_SHIPPING',
    EXPORTED_WAREHOUSE: 'EXPORTED_WAREHOUSE',
    EXPRESS_SHIPPING: 'EXPRESS_SHIPPING',
    FRAGILE_BUBBLE_WRAPPING: 'FRAGILE_BUBBLE_WRAPPING',
    FRAGILE: 'FRAGILE',
    FREIGHT_SHIPPING: 'FREIGHT_SHIPPING',
    IMPORTED_WAREHOUSE: 'IMPORTED_WAREHOUSE',
    INSPECTION: 'INSPECTION',
    LOHA: 'LOHA',
    LOHI: 'LOHI',
    LOST: 'LOST',
    MISSING_ORDER: 'MISSING_ORDER',
    NOT_IN_BAG: 'NOT_IN_BAG',
    NOT_MATCH: 'NOT_MATCH',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    PACKAGED: 'PACKAGED',
    SEA_SHIPPING: 'SEA_SHIPPING',
    SHIPMENT: 'SHIPMENT',
    SPECIAL_BUBBLE_WRAPPING: 'SPECIAL_BUBBLE_WRAPPING',
    SPECIAL_PACKAGING: 'SPECIAL_PACKAGING',
    STANDARD_BUBBLE_WRAPPING: 'STANDARD_BUBBLE_WRAPPING',
    STANDARD_PACKAGING: 'STANDARD_PACKAGING',
    STORAGE_TOO_LONG: 'STORAGE_TOO_LONG',
    SUFFICIENT_ECOMMERCE_CONDITION: 'SUFFICIENT_ECOMMERCE_CONDITION',
    SUPER_FRAGILE_BUBBLE_WRAPPING: 'SUPER_FRAGILE_BUBBLE_WRAPPING',
    THAN_AMOUNT_ORDER: 'THAN_AMOUNT_ORDER',
    UNPROCESSED: 'UNPROCESSED',
    WOOD_PACKAGING: 'WOOD_PACKAGING',
    WRONG_BAG: 'WRONG_BAG',
    WRONG_CUSTOMER: 'WRONG_CUSTOMER',
    WRONG_SHIPPING_PARTNER: 'WRONG_SHIPPING_PARTNER',
    IN_SOURCE_WAREHOUSE: 'IN_SOURCE_WAREHOUSE',
    WRONG_FLOW: 'WRONG_FLOW',
    BIFIN_ADVANCE: "BIFIN_ADVANCE",
    DISTRIBUTING_WAREHOUSE_STORAGE: "DISTRIBUTING_WAREHOUSE_STORAGE",
    BARCODE_SCANNING_DUPLICATE: "BARCODE_SCANNING_DUPLICATE",
    WRONG_AGENCY: "WRONG_AGENCY",
    SBM_REAL_ECOM: "SBM_REAL_ECOM",
    NO_CHECKING: "NO_CHECKING",
    INACTIVE: "INACTIVE",
    SUPERFAST_SHIPPING: "SUPERFAST_SHIPPING",
};
export const LIST_ERROR_SOUND = [
    CHECKING_PACKAGE.FAILED,
    SCAN_BARCODE_FORM.FAILED,
    TRANSPORT_WAREHOUSE.FAILED,
    PACKING_PACKAGE.FAILED,
    PACKING_PACKAGE_LASTMILE.FAILED,
    CREATE_PACKAGE_BY_SCAN.FAILED,
    SCAN_BARCODE.FAILED,
    SCAN_PACKAGE.FAILED,
    SCAN_TRACKING_BILL.FAILED,
    SOUND_CONSTANT.INPUT_INVALID,
    SOUND_CONSTANT.BARCODE_NOT_EXIST,
    SOUND_CONSTANT.PACKAGE_NOT_FOUND,
    SOUND_CONSTANT.BAG_NOT_FOUND,
    SOUND_CONSTANT.SOUND_SCAN_ERROR,
    SOUND_CONSTANT.ERROR_SCAN_BAG_PACKING,
    SOUND_CONSTANT.ERROR_SCAN_PACKAGE_NOT_VALID,
    SOUND_CONSTANT.ERROR_SCAN_IMPORT_BAG_SINGLE_COMPLETED,
    SOUND_CONSTANT.BAG_SHIPPING_PARTNER_IS_DIFFERENT_FROM_PACKAGE_SHIPPING_PARTNER,
    
];
