import React, { Component } from 'react'
import { Typography } from 'antd'
import { get, isEmpty, round } from 'lodash'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'

import { currentFormatter, dateFormatter } from '../../services/format'
import { finishLoadPrintTemplate, loadPrintTemplate } from '../actions'

const { Paragraph, Text } = Typography

class Print9710PackageStamp extends Component {
    constructor(props) {
        super()

        props.loadPrintTemplate()
    }

    handleDidMount = () => {
        const { finishLoadPrintTemplate, onDidMount } = this.props

        finishLoadPrintTemplate()
        onDidMount()
    }

    componentDidMount() {
        const { logo } = this.props
        const packageData = this.props.package
        const isBatch = get(packageData, 'is_batch', false)

        if (isBatch || !logo) {
            this.handleDidMount()
        }
    }

    render() {
        let { manifests, destination_warehouse, type_temp_print, max_value, products, total_value, order, services, properties, warehouse_services } =
            this.props
        const packageData = this.props.package
        let totalAmount = total_value > 0 && total_value < max_value ? total_value : null
        if (max_value === null) {
            //nếu không cấu hình thì hiển thị đúng giá trị
            totalAmount = total_value
        }
        if (max_value === 0) {
            //Có thể giá trị = 0, khi giá trị cấu hình là 0 thì auto không hiển thị giá trị này
            totalAmount = null
        }

        let toName = get(manifests, 'customer_receive_name')
        let toMobile = get(manifests, 'customer_receive_phone')
        let toAddress = get(manifests, 'customer_receive_address')
        const sender = get(manifests, 'sender', '')

        const productName = get(products, '0.manifest_translated_name') || get(products, '0.translated_name') || get(products, '0.original_name')

        return (
            <div
                className="print-package-official-stamp print-package-official-stamp-custom"
                style={{ border: 'none' }}>
                <div className="a-flex">
                    <div
                        className="logo a-flex gflex-direction-column"
                        style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px' }}>
                        <img
                            style={{ objectFit: 'contain' }}
                            alt="shopee"
                            className="partner-logo"
                            src="https://assets-vns.mygobiz.net/m6/images/kititu.JPG"
                            onLoad={this.handleDidMount}
                            onError={this.handleDidMount}
                        />
                        <div className={'mt-2'}>Từ: {sender}</div>
                    </div>

                    {(toName || toMobile || toAddress) && (
                        <div
                            className="customer-info"
                            style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px' }}>
                            <Paragraph className="mb-4 a-text--fz-18">Đến: {toName}</Paragraph>
                            <Paragraph className="mb-0">Số điện thoại: {toMobile}</Paragraph>
                            <Paragraph className="mb-0">Địa chỉ: {toAddress}</Paragraph>
                        </div>
                    )}
                </div>
                <div
                    className="barcode"
                    style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px', textAlign: 'center' }}>
                    <Barcode
                        copyStyles={true}
                        height={80}
                        margin={0}
                        value={get(packageData, 'code')}
                        width={3.5}
                        fontSize={28}
                        fontOptions={'bold'}
                        font={'Roboto, sans-serif'}
                        textMargin={10}
                    />
                    <span
                        style={{
                            fontSize: '20px',
                        }}>
                        ({get(packageData, 'barcode', '---')})
                    </span>
                </div>
                <div style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px' }}>
                    <Paragraph
                        className="mb-2 a-text--fz-18 a-text--center"
                        strong>
                        Order No.{' '}
                        <Text
                            style={{
                                fontSize: '20px',
                            }}>
                            {get(order, 'code')}
                        </Text>
                    </Paragraph>
                    <div className="a-flex">
                        <div className="width-50-pc">
                            <Paragraph
                                className="mb-0 a-text--fz-24"
                                strong>
                                {destination_warehouse}
                            </Paragraph>
                            {type_temp_print && <Paragraph className="mb-2 a-text--fz-16">({type_temp_print})</Paragraph>}
                            <Paragraph className="mb-0 a-text--fz-18">CN9710</Paragraph>
                        </div>
                        <div className="width-50-pc">
                            <Paragraph className="mb-4 a-text--fz-18">
                                Properties:
                                <span className="a-text--bold">{(properties || []).join(', ')}</span>
                            </Paragraph>
                            <Paragraph className="mb-4 a-text--fz-18">
                                Services:
                                <span className="a-text--bold">{([...services, ...warehouse_services] || []).join(', ')}</span>
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className="product-info a-flex">
                    <div
                        className="width-50-pc"
                        style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px' }}>
                        {!isEmpty(products) && (
                            <div className={'a-flex gflex-direction-column'}>
                                <Paragraph strong>Nội dung hàng: (Tổng số lượng 1 sản phẩm)</Paragraph>
                                <Paragraph className="mb-0">{productName}</Paragraph>
                            </div>
                        )}
                    </div>
                    <div
                        className="user-info width-50-pc a-flex gflex-direction-column a-text--center"
                        style={{ borderRadius: '4px', border: '2px solid #c2cfd9' }}>
                        <div style={{ height: '50%', borderBottom: '2px solid #c2cfd9', padding: '10px' }}>
                            <Paragraph
                                className="mb-2 a-text--fz-16"
                                strong>
                                User:{' '}
                                <Text className="a-text--fz-24 a-text--uppercase">
                                    {get(order, 'customer_username') || get(packageData, 'customer_username')}
                                </Text>
                            </Paragraph>
                        </div>
                        <div
                            style={{
                                padding: '10px',
                            }}>
                            Ngày đặt hàng: {dateFormatter.datedash(packageData.created_at)}
                        </div>
                    </div>
                </div>
                <div
                    className="footer"
                    style={{ borderRadius: '4px', border: '2px solid #c2cfd9', padding: '10px' }}>
                    <div className="a-flex galign-baseline">
                        <div className="a-text--fz-24 width-50-pc a-text--bold">Tiền thu người nhận</div>
                        <div>Khối lượng tối đa: {round(parseFloat(get(packageData, 'weight_net')) + parseFloat(get(packageData, 'weight_box')), 4)}kg</div>
                    </div>
                    <div className="a-flex galign-baseline">
                        <div className="a-text--fz-24 width-50-pc a-text--bold a-text--center galign-self-center">
                            {totalAmount ? currentFormatter.toLocaleStringCurrency(totalAmount) + ' VNĐ' : ''}
                        </div>

                        <div
                            className="a-text--center"
                            style={{
                                borderRadius: '4px',
                                border: '2px solid #c2cfd9',
                                padding: '10px',
                                paddingTop: 'none',
                            }}>
                            <p className="mb-0 a-text--bold">Chữ ký người nhận</p>
                            <p style={{ paddingBottom: '30px' }}>(Xác nhận không móp/méo, bể vỡ)</p>
                        </div>
                    </div>
                    <div className="pt-5 a-text--italic a-text--center a-text--fz-12">
                        Chỉ dẫn giao hàng: Không đồng kiểm, chuyển hoàn sau 3 lần phát. Lưu 5 ngày
                    </div>
                </div>
            </div>
        )
    }
}

Print9710PackageStamp.defaultProps = {
    onDidMount: () => {},
}

Print9710PackageStamp.propTypes = {
    onDidMount: PropTypes.func,
}

export default connect(undefined, {
    finishLoadPrintTemplate,
    loadPrintTemplate,
})(Print9710PackageStamp)
