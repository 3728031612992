import React from 'react';
import { Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import lodash, { get, find, isEqual } from 'lodash'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DEFAULT_STATE_FORM_CUSTOMER, DELIVERY_NOTE_STATUS } from '../../constants';
import { formatCurrency, removeDotFromNumber, replaceText } from '../../../Common/services/helps';
import { getSettingItemByCode } from '../../../Setting/selectors';
import { getState } from '../../../ShippingPartner/ListShippingPartner/selectors';

import ShippingDomesticPartnersSelect from './../../../Common/components/Selects/ShippingDomesticPartnersSelect';
import LoanByOrders from './LoanByOrders';
import { linkHTC } from '../../../Package/helper';
import { getState as systemGetState } from '../../../../system/store';
import { getVar } from '../../../../system/support/helpers';

const { TextArea } = Input;
const { Text } = Typography;

const usingShippingDomesticPartner = getSettingItemByCode('CONFIG:USING_SHIPPING_DOMESTIC_PARTNER', false);
const defaultShippingDomesticPartner = getSettingItemByCode('CONFIG:DEFAULT_SHIPPING_DOMESTIC_PARTNER');

class CustomerFormInput extends React.Component {

    state = {
        ...DEFAULT_STATE_FORM_CUSTOMER,
        id_last_mile_carrier: undefined,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.customerInfo !== this.props.customerInfo) {
            const defaultPhone = find(get(this.props, "customerInfo.phone"), {default: true});
            const defaultAddress = find(get(this.props, "customerInfo.address"), {default: true});
            if(!this.state.customer_phone && !this.state.customer_address || !isEqual(prevProps.packages , this.props.packages)) {
                this.setState({
                    customer_address: get(defaultAddress, "value"),
                    customer_phone: get(defaultPhone, "value"),
                });
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nextState = {...state};
        const deliveryNoteId = lodash.get(props.deliveryNote, 'id');
        const customerId = !deliveryNoteId ? lodash.get(props, 'id_customer', 0) : lodash.get(props, 'deliveryNote.id_customer', '');
        if (state.deliveryNoteId !== deliveryNoteId) {
            nextState = !deliveryNoteId ? {...DEFAULT_STATE_FORM_CUSTOMER} : {
                note: lodash.get(props.deliveryNote, 'note', ""),
            };
            if (!isEqual(get(state, 'packages'), get(props, 'packages'))) {
                nextState.customer_address = lodash.get(props.deliveryNote, 'customer_address', "")
                nextState.customer_phone = lodash.get(props.deliveryNote, 'customer_phone', "")
            }
            nextState.domestic_shipping_fee = lodash.get(props.deliveryNote, 'domestic_shipping_fee');
            nextState.lastmile_operation_fee = lodash.get(props.deliveryNote, 'lastmile_operation_fee');
            nextState.cod = lodash.get(props.deliveryNote, 'cod');
            nextState.deliveryNoteId = deliveryNoteId;
            nextState.id_last_mile_carrier = lodash.get(props.deliveryNote, 'id_last_mile_carrier');
        }

        if (state.id_customer !== customerId) {
            nextState.id_customer = customerId
        }

        if (!lodash.isEqual(state.deliveryNote, props.deliveryNote)) {
            nextState.deliveryNote = props.deliveryNote;
            nextState.length = lodash.get(props.deliveryNote, 'length');
            nextState.width = lodash.get(props.deliveryNote, 'width');
            nextState.height = lodash.get(props.deliveryNote, 'height');
        }

        if (state.deliveryNoteId !== deliveryNoteId || state.id_customer !== customerId) {
            nextState.customer_receiver = !deliveryNoteId ? lodash.get(props.customer, 'full_name', '') :  lodash.get(props.deliveryNote, 'customer_receiver', '');

            if (deliveryNoteId) {
                nextState.negative_balance = lodash.get(props.deliveryNote, 'negative_balance');
            } else if (lodash.get(props.customerFinance, 'amount_remaining') < 0) {
                nextState.negative_balance = Math.abs(props.customerFinance.amount_remaining);
                nextState.cod = nextState.negative_balance;
            }
        }

        nextState.bags = props.bags;
        nextState.packages = props.packages;

        return nextState;
    }

    onChangeInput = (event, valueInput = 0) => {
        const { errors, identity, onUpdate, resetError } = this.props;
        const value = event.target ? event.target.value : valueInput;
        const name = event.target ? event.target.name : event;

        this.setState({
            [name]: value
        }, () => {
            this.doCheckUpdateCOD(name);
            onUpdate(lodash.omit({
                ...this.state
            }, 'deliveryNote'));
            if (errors[name]) {
                resetError({
                    identity,
                    field: name
                });
            }
        });
    };

    onChangeSelectShippingDomesticPartner = value => {
        const { errors, identity, onUpdate, resetError } = this.props;

        const newState = {
            id_last_mile_carrier: value
        };

        if (value) {
            newState.domestic_shipping_fee = undefined;
            newState.negative_balance = undefined;
            newState.cod = undefined;
        }

        this.setState(newState, () => {
            onUpdate(lodash.omit({
                ...this.state
            }, 'deliveryNote'));
            if (errors.id_last_mile_carrier) {
                resetError({
                    identity,
                    field: 'id_last_mile_carrier'
                });
            }

            if (value && errors.domestic_shipping_fee) {
                resetError({
                    identity,
                    field: 'domestic_shipping_fee'
                });
            }

            if (value && errors.negative_balance) {
                resetError({
                    identity,
                    field: 'negative_balance'
                });
            }

            if (value && errors.cod) {
                resetError({
                    identity,
                    field: 'cod'
                });
            }
        });
    };

    checkUpdateCOD = name => {
        const { domestic_shipping_fee , negative_balance } = this.state;

        // auto update cod when update domestic_shipping_fee or negative_balance
        if (['domestic_shipping_fee', 'negative_balance'].includes(name)) {
            const newCOD = (Number(domestic_shipping_fee) || 0) + (Number(negative_balance) || 0);

            this.setState({
                cod: newCOD
            });

            this.props.onUpdate({
                ...this.state, cod: newCOD
            });
        }
    };

    doCheckUpdateCOD = lodash.debounce(this.checkUpdateCOD, 250);

    handleChangeCustomerPhoneAndAddress = (field, value) => {
        const { identity } = this.props;
        const _value = lodash.last(value);
        this.setState({[field]: _value}, () => {
            this.props.onUpdate({
                ...this.state,
                [field]: _value
            });
        });

        if (this.props.errors[field]) {
            this.props.resetError({
                identity,
                field
            });
        }
    };

    getCustomerPhones = () => {
        const {customerInfo} = this.props;
        return lodash.get(customerInfo, 'phone', []) || [];
    };

    getCustomerAddresses = () => {
        const {customerInfo} = this.props;
        return lodash.get(customerInfo, 'address', []) || [];
    };

    render() {
        const { deliveryNote, errors ,loading, loadingShippingDomesticPartnerSuggests, shippingDomesticPartnerSuggests, t, usingShippingDomesticPartner, readyForDeliveries, customer, agency, agencySetting, globalSetting } = this.props;
        const customerPhones = this.getCustomerPhones();
        const customerAddresses = this.getCustomerAddresses();
        const deliveryNoteId = getVar(deliveryNote, 'id')
        const id_agency = get(customer, "id_agency");
        const hasPermission = getVar(find(agencySetting, {code: "CONFIG:LASTMILE_DELIVERY_REQUEST", id_agency}), "value", get(find(globalSetting, ["code", "CONFIG:LASTMILE_DELIVERY_REQUEST"]), "value", ));
        const linkHtc = linkHTC(get(agency, 'code', ''))

        return (
            <>
                <Row gutter={{lg: 24, xl: 32}} className="a-form pt-5 mx-0">
                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.customer_receiver}
                            label={t('delivery_note:label.customer_receiver')}
                            labelCol={{span: 24}}
                            required
                            validateStatus={errors.customer_receiver ? 'error' : null}
                        >
                            <Input
                                className="customer-input a-input _person_receive_customer width-100-pc"
                                disabled={loading || hasPermission}
                                name="customer_receiver"
                                placeholder={t('delivery_note:placeholder.customer_receiver')}
                                value={(hasPermission && !deliveryNoteId) ? undefined : this.state.customer_receiver}
                                onChange={this.onChangeInput.bind(this)}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.shipping_fee}
                            label={t('shipping_fee')}
                            labelCol={{span: 24}}
                            validateStatus={errors.shipping_fee ? 'error' : null}
                        >
                            <InputNumber
                                className="a-input _domestic-shipping-fee width-100-pc"
                                disabled={hasPermission || !!(loading || this.state.id_last_mile_carrier)}
                                formatter={value => formatCurrency(value)}
                                placeholder={t('placeholder.shipping_fee')}
                                max={9999999999999.999}
                                min={0}
                                name="domestic_shipping_fee"
                                parser={value => removeDotFromNumber(replaceText(value))}
                                value={this.state.domestic_shipping_fee}
                                onChange={this.onChangeInput.bind(this, 'domestic_shipping_fee')}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.negative_balance}
                            label={t('negative_balance')}
                            labelCol={{span: 24}}
                            validateStatus={errors.negative_balance ? 'error' : null}
                        >
                            <InputNumber
                                className="a-input _negative-balance width-100-pc"
                                disabled={hasPermission || !!(loading || this.state.id_last_mile_carrier)}
                                formatter={value => formatCurrency(value)}
                                placeholder={t('placeholder.negative_balance')}
                                max={9999999999999.999}
                                min={0}
                                name="negative_balance"
                                parser={value => removeDotFromNumber(replaceText(value))}
                                value={this.state.negative_balance}
                                onChange={this.onChangeInput.bind(this, 'negative_balance')}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.cod}
                            label="COD"
                            labelCol={{span: 24}}
                            validateStatus={errors.cod ? 'error' : null}
                        >
                            <InputNumber
                                className="a-input _cod width-100-pc"
                                disabled={hasPermission || (!!(loading || this.state.id_last_mile_carrier))}
                                formatter={value => formatCurrency(value)}
                                placeholder={t('placeholder.cod')}
                                max={9999999999999.999}
                                min={0}
                                name="cod"
                                parser={value => removeDotFromNumber(replaceText(value))}
                                value={this.state.cod}
                                onChange={this.onChangeInput.bind(this, 'cod')}
                            />
                        </Form.Item>
                    </Col>
                    {usingShippingDomesticPartner ? (
                        <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                            <Form.Item
                                className="a-form__item"
                                help={errors.id_last_mile_carrier}
                                label={t('shipping_partner')}
                                labelCol={{span: 24}}
                                validateStatus={errors.cod ? 'error' : null}
                            >
                                <ShippingDomesticPartnersSelect
                                    allowClear
                                    className="a-input _id-partner-shipping-domestic width-100-pc"
                                    disabled={[DELIVERY_NOTE_STATUS.PENDING, DELIVERY_NOTE_STATUS.TRACKING].includes(lodash.get(deliveryNote, 'status'))}
                                    loading={loadingShippingDomesticPartnerSuggests}
                                    shippingDomesticPartners={shippingDomesticPartnerSuggests}
                                    value={this.state.id_last_mile_carrier}
                                    onChange={this.onChangeSelectShippingDomesticPartner}
                                />
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col className="a-form--group" lg={{span: 12}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.length || errors.width || errors.height}
                            label={t('lwh_dimension')}
                            labelCol={{span: 24}}
                            validateStatus={errors.length || errors.width || errors.height ? 'error' : null}
                        >
                            <Row className="width-100-pc" gutter={8} type="flex">
                                <Col span={8}>
                                    <InputNumber
                                        className="a-input _length width-100-pc"
                                        disabled={!!loading}
                                        formatter={value => formatCurrency(value)}
                                        max={9999999999999.999}
                                        min={0}
                                        name="length"
                                        parser={value => replaceText(value)}
                                        value={this.state.length}
                                        onChange={this.onChangeInput.bind(this, 'length')}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        className="a-input _width width-100-pc"
                                        disabled={!!loading}
                                        formatter={value => formatCurrency(value)}
                                        max={9999999999999.999}
                                        min={0}
                                        name="width"
                                        parser={value => replaceText(value)}
                                        value={this.state.width}
                                        onChange={this.onChangeInput.bind(this, 'width')}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        className="a-input _height width-100-pc"
                                        disabled={!!loading}
                                        formatter={value => formatCurrency(value)}
                                        max={9999999999999.999}
                                        min={0}
                                        name="height"
                                        parser={value => replaceText(value)}
                                        value={this.state.height}
                                        onChange={this.onChangeInput.bind(this, 'height')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" lg={{span: 24}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.lastmile_operation_fee}
                            label={t('label.lastmile_operation_fee')}
                            labelCol={{span: 24}}
                            validateStatus={errors.lastmile_operation_fee ? 'error' : null}
                        >
                            <InputNumber
                                className="a-input _lastmile_operation_fee width-100-pc"
                                formatter={value => formatCurrency(value)}
                                placeholder={t('placeholder.lastmile_operation_fee')}
                                max={9999999999999.999}
                                min={0}
                                name="lastmile_operation_fee"
                                parser={value => removeDotFromNumber(replaceText(value))}
                                value={this.state.lastmile_operation_fee}
                                onChange={this.onChangeInput.bind(this, 'lastmile_operation_fee')}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="a-form--group" lg={{span: 24}} xl={{span: 6}}>
                        <Form.Item
                            className="a-form__item"
                            help={errors.note}
                            label={t('label.note')}
                            labelCol={{span: 24}}
                            validateStatus={errors.note ? 'error' : null}
                        >
                            <TextArea
                                allowClear={true}
                                autosize={{minRows: 1, maxRows: 3}}
                                className="a-input--textarea _note_export_employee"
                                name="note"
                                placeholder={t('placeholder.note')}
                                spellCheck={false}
                                value={this.state.note}
                                onChange={this.onChangeInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="mx-0">
                    <Col>
                        <Text type="secondary">
                            * <i>{t("package:note.auto_update_customer_info")}</i>
                        </Text>
                    </Col>
                </Row>
                <Row gutter={{lg: 24, xl: 32}} className="pt-5 mr-0 ml-0">
                    <Col lg={{span: 12}} xl={{span: 6}} xxl={{span: 6}}
                         className="a-form--group mb-0-xl ">
                        <Form.Item className="a-form__item customer-select "
                                   validateStatus={errors.customer_phone ? 'error' : null}
                                   help={errors.customer_phone}
                                   label={t("delivery_note:label.customer_phone")}
                                   labelCol={{span: 24}}>
                            <Select
                                className="_customer_phone customer-select-without-truncate"
                                value={this.state.customer_phone}
                                // value={(hasPermission && !deliveryNoteId) ? undefined : (this.state.customer_phone ? this.state.customer_phone : undefined)}
                                allowClear={true}
                                mode={'tags'}
                                maxTagCount={1}
                                disabled={hasPermission}
                                placeholder={t("delivery_note:placeholder.customer_phone")}
                                onChange={this.handleChangeCustomerPhoneAndAddress.bind(this, 'customer_phone')}
                            >
                                {customerPhones.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                         className="a-form--group mb-0-xl ">
                        <Form.Item className="a-form__item  customer-select"
                                   validateStatus={errors.customer_address ? 'error' : null}
                                   help={errors.customer_address}
                                   label={t("delivery_note:label.customer_address")}
                                   labelCol={{span: 24}}>
                            <Select
                                className="customer-select customer-select-without-truncate _customer_address"
                                value={this.state.customer_address}
                                // value={(hasPermission && !deliveryNoteId) ? undefined : (this.state.customer_address ? this.state.customer_address : undefined)}
                                allowClear={true}
                                mode={'tags'}
                                disabled={hasPermission}
                                maxTagCount={1}
                                placeholder={t("delivery_note:placeholder.customer_address")}
                                onChange={this.handleChangeCustomerPhoneAndAddress.bind(this, 'customer_address')}
                            >
                                {customerAddresses.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={{lg: 24, xl: 32}} className="pt-5 mr-0 ml-0">
                    <Col lg={{span: 12}} xl={{span: 12}}
                         className="a-form--group mb-0-xl gflex galign-center ">
                        <LoanByOrders readyForDeliveries={readyForDeliveries} customer={customer}/>
                        {linkHtc &&
                            <a  href={`${linkHtc}/user/chi-tiet/${get(customer,'username')}`} className="ml-8 a-text--fz-16" rel="noopener noreferrer" target='_blank'>
                                {t("package:label.financial_check_and_finalization")}
                            </a>
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

CustomerFormInput.defaultProps = {
    id_customer: 0,
    errors: {},
    loading: false,
    customer: {},
    deliveryNote: {},
    customerInfo: {}
};

CustomerFormInput.propTypes = {
    customer: PropTypes.object,
    id_customer: PropTypes.number,
    loading: PropTypes.bool,
    resetError: PropTypes.func,
    onUpdate: PropTypes.func,
    errors: PropTypes.object,
    deliveryNote: PropTypes.object
};

const mapStateToProps = state => {
    return {
        usingShippingDomesticPartner: usingShippingDomesticPartner(state),
        defaultShippingDomesticPartner: defaultShippingDomesticPartner(state),
        shippingDomesticPartnerSuggests: getState(state, 'lastmileCarriers.suggests', []),
        loadingShippingDomesticPartnerSuggests: getState(state, 'lastmileCarriers.loadingSuggests', []),
        agencySetting: systemGetState('setting.agencySetting'),
        globalSetting: systemGetState('setting.setting'),
    };
};

export default withTranslation()(connect(
    mapStateToProps
)(CustomerFormInput));
