import {useEffect, useState} from "react";
import queryString from "query-string";
import { isEmpty, isEqual, omitBy } from 'lodash'

export default function useFilter(history, location, initFilters = {}) {
    const [filters, setFilters] = useState(formatFilters(initFilters));
    const currentFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});

    /**
     * Chỉ thêm các tiêu chí khi có giá trị
     * Nếu initFilters = {} thì khi vào DS sẽ load dữ liệu ko có tiêu chí lọc
     */
    useEffect(() => {
        if (isEmpty(location.search) && !isEmpty(initFilters)) {
            addFilter(formatFilters(initFilters), true);
        } else {
            let tmpFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});
            addFilter(formatFilters(tmpFilters));
        }
    }, [location.search]);

    function formatFilters(params) {
        return omitBy(params, (value) => value === "");
    }

    /**
     * Chỉ thêm các tiêu chí khi có giá trị
     * Nếu lọc hiện tại (currentFilters) và lọc mới (filter) giống nhau thì ko thực hiện gì
     * Nếu initFilters = {} push location hiện tại vào history
     * Nếu location.search = "" và initFilters có giá trị thì replace location hiện tại = location mặc định trong history
     * Nếu location.search != "" thì push location mới vào history
     */
    function addFilter(filter, isReplaced = false) {
        setFilters(formatFilters(filter));

        if (isEqual(currentFilters, filter) === true)
            return;

        if (isEmpty(filter)) {
            history.push(location.pathname);
        } else {
            let newUrl = location.pathname + '?' + queryString.stringify(formatFilters({...currentFilters, ...filter}), {arrayFormat: 'bracket'});
            isReplaced ? history.replace(newUrl) : history.push(newUrl);
        }
    }

    return {filters, addFilter};
}
