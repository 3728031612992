import React, { memo, useEffect, useMemo, useState } from 'react'
import { t, trans } from '../../../../system/i18n'
import { useGetTasksConsignmentQueries } from '../../../../hooks/package/useGetTasksConsignmentQueries'
import { notification, Table, Tag } from 'antd'
import { getVar } from '../../../../system/support/helpers'
import { forEach, map, size, some } from 'lodash'
import TableNested from './TableNested'
import withServiceGroups from '../../../../hocs/withServiceGroups'
import withConsignServiceGroups from '../../../../hocs/withConsignServiceGroups'
import isEmpty from 'lodash/isEmpty'

const ListTask = ({listTaskId, serviceGroups, consignServiceGroups}) => {
    const [disableInterval, setDisableInterval] = useState(isEmpty(listTaskId));

    useEffect(() => {
        setDisableInterval(false)
    }, [listTaskId])

    useEffect(() => {
        let timeoutId = null;
        if (!disableInterval) {
            timeoutId = setTimeout(() => {
                setDisableInterval(true);
            }, 60*1000*10);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [disableInterval]);

    const queriesData = useGetTasksConsignmentQueries(listTaskId,  {
        onSuccess: (data) => {
            const status  = getVar(data, 'task.status', '')
            if(status === "success") {
                notification.success({message: trans("package_consignment:message.create_package_success", {
                        code: getVar(data, "task.order.code"),
                    }), key: getVar(data, "task.order.code")});
            }
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: (data) => {
            const status  = getVar(data, 'task.status', '')
            if(status === "success" || disableInterval) {
                return false
            }
            return 10000
        }})

    const isLoading = some(queriesData, {isLoading: true})
    const data = useMemo(() => {
        const dataRes = []
             forEach(queriesData, (data) => {
                const task = getVar(data, 'data', {})
                if (!isEmpty(task)) return dataRes.push(task)
            })
        return dataRes
    })

    const findServiceName = (id) => {
        let idServiceName = null

        const checkServiceGroups = (groups) => {
            forEach(groups, group => {
                forEach(getVar(group, "services"), service => {
                    if (service.id === id) {
                        idServiceName = service.name
                    }
                })
            })
        }

        checkServiceGroups(serviceGroups)
        if (!idServiceName) {
            checkServiceGroups(consignServiceGroups)
        }

        return idServiceName
    }

    const columns = [
        {
            title: t('package_consignment:column.order_code'),
            dataIndex: 'task.order',
            key: 'order',
            width: 200,
            render: (order) => {
                return getVar(order, 'code', '')
            }
        },
        {
            title: t('package_consignment:column.bag_code'),
            dataIndex: 'task.bag',
            key: 'bag',
            width: 200,
            render: (bag) => {
                return getVar(bag, 'code', '')
            }
        },
        {
            title: t('package_consignment:column.service'),
            dataIndex: 'task.payload.create_order_shipment_by_customer_data.id_services',
            key: 'id_services',
            render: (id_services) => map(id_services, (id, idx) => {
                const serviceName = findServiceName(id)
                return <>
                    <span>{serviceName}</span>
                    {idx !== size(id_services) - 1 && <span>, </span>}
                </>
            })
        },
        {
            title: t('package_consignment:column.status'),
            dataIndex: 'task.status',
            key: 'status',
            width: 200,
            render: (status) => {
                let tagColor = "blue"
                if (status === "success") {
                    tagColor = "green"
                }
                if(status === "pending") {
                    tagColor = "orange"
                }
                return <Tag color={tagColor}>{status}</Tag>
            }
        }
    ]

    return <div className="block--primary">
        <div className="title left">
            <span className="a-text--bold">{t('package_consignment:label.order_list')}</span>
        </div>
        <div className="body not-boxshadow mb-8">
            <Table loading={isLoading} bordered dataSource={data} columns={columns} pagination={true} expandedRowRender={(record) => <TableNested data={getVar(record, 'task.package_codes', [])}/>}/>
        </div>
    </div>
}

export default memo(withServiceGroups(withConsignServiceGroups(ListTask)))