import React from 'react'
import { notification } from 'antd'
import moment from 'moment'
import lodash, { find, get } from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as actions from './actions'
import * as selectors from './selectors'
import SearchableComponent from '../../Common/components/SearchableComponent'
import ListPackage from './components'
import * as commonAction from '../../Common/actions'

import apiService from './../apiService'
import { handleDownloadTransportStatusError } from './../helper'
import { convertBlobDataToExcel, isExportToMail, removeNilProperty } from './../../../system/support/helpers'
import withConsignServices from './../../../hocs/withConsignServices'
import withProperties from './../../../hocs/withProperties'
import withServices from './../../../hocs/withServices'
import { getSettingItemByCode } from './../../Setting/selectors'
import authService from '../../Auth/authService'

const orrderCreateShipmentGenerator = getSettingItemByCode('ORDER:CREATE_SHIPMENT', false)

const mapStateToProps = state => {
    return {
        ...selectors.getState(state),
        setting: lodash.get(state, 'setting.setting', []),
        orderCreateShipment: orrderCreateShipmentGenerator(state),
    }
}

const mapDispatchToProps = dispatch => ({
    setMenuActive: menu => dispatch(commonAction.setMenuActive(menu)),
    fetchPackages: filter => dispatch(actions.fetchPackages(filter)),
    exportPackageList: params => dispatch(actions.exportPackageList(params)),
})

const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
const sixMonthsAgo = moment(currentDate).subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss')

class ListPackageContainer extends SearchableComponent {
    state = {
        downloadingTransportStatus: false,
    }

    onSearch(filter) {
        if (filter['length_of_transport_status[]']) {
            filter['length_of_transport_status[]'] = removeNilProperty(filter['length_of_transport_status[]'])

            if (Object.keys(filter['length_of_transport_status[]']).length) {
                filter['length_of_transport_status[]'] = JSON.stringify(filter['length_of_transport_status[]'])
            }
        }

        this.pushFilter({
            ...filter,
            i: parseInt(filter.i || 0, 0) + 1,
        })
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.setting) !== JSON.stringify(this.props.setting) ||
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.prepareFilter(this.props)
        }
    }

    onChangeFilter(filter) {
        if (filter && filter.length_of_transport_status && filter.length_of_transport_status[0]) {
            try {
                const lengthOfTransportStatus = JSON.parse(filter.length_of_transport_status[0])

                if (lengthOfTransportStatus && lengthOfTransportStatus.time_from) {
                    lengthOfTransportStatus.time_from = moment(lengthOfTransportStatus.time_from).format('YYYY-MM-DD HH:mm:ss')
                }

                if (lengthOfTransportStatus && lengthOfTransportStatus.time_to) {
                    lengthOfTransportStatus.time_to = moment(lengthOfTransportStatus.time_to).format('YYYY-MM-DD HH:mm:ss')
                }

                filter.length_of_transport_status[0] = JSON.stringify(lengthOfTransportStatus)
            } catch (error) {}
        }
        if (!lodash.get(filter, 'created_at[from]')) {
            filter['created_at[from]'] = sixMonthsAgo
        }
        let newFilter = filter
        let configPackageUnitWeight = get(find(this.props.setting, ['code', 'PACKAGE:UNIT_WEIGHT']), 'value', 'g')

        if (lodash.get(filter, 'weight_net[from]') && configPackageUnitWeight === 'kg') {
            const weightNetFrom = lodash.get(filter, 'weight_net[from]')
            newFilter['weight_net[from]'] = Number(weightNetFrom) * 10
        }
        if (lodash.get(filter, 'weight_net[to]') && configPackageUnitWeight === 'kg') {
            const weightNetTo = lodash.get(filter, 'weight_net[to]')
            newFilter['weight_net[to]'] = Number(weightNetTo) * 10
        }

        this.props.fetchPackages(newFilter)
    }

    getDefaultFilter() {
        return {
            page: 1,
            sort_by: this.props.sort_by || 'created_at',
            sort_direction: this.props.sort_direction || 'desc',
            'created_at[from]': sixMonthsAgo,
        }
    }

    getCurrentFilter() {
        const { location, orderCreateShipment } = this.props

        const filter = {
            ...this.getDefaultFilter(),
            ...this.getFilterFromLocation(location),
        }

        if (filter.is_shipment) {
            if (filter.is_shipment === 'true' && !orderCreateShipment) {
                filter.is_shipment = false
            } else {
                filter.is_shipment = true
            }
        }

        return filter
    }

    handleDownloadTransportStatuses = async params => {
        const { t } = this.props

        this.setState({
            downloadingTransportStatus: true,
        })

        try {
            const response = await apiService.downloadTransportStatus(params)
            const reader = new FileReader()
            let message = t('package:message.download_transport_status.success')

            reader.addEventListener('loadend', event => {
                const text = event.target.result
                const now = moment()
                let fileName = now.format('DD_MM_YYYY')

                if (params && params.length_of_transport_status && params.length_of_transport_status[0]) {
                    const lengthOfTransportStatus = JSON.parse(params.length_of_transport_status[0])
                    const state = lengthOfTransportStatus.state.toLowerCase()
                    const compare = lengthOfTransportStatus.compare
                    const day = lengthOfTransportStatus.day
                    let compareText = ''

                    if (compare === '>') {
                        compareText = 'morethan'
                    }

                    if (compare === '<') {
                        compareText = 'lessthan'
                    }

                    fileName = `${state}_${compareText}${day ? `${day}day_` : ''}${fileName}`
                }

                try {
                    const data = JSON.parse(text)

                    if (isExportToMail(data)) {
                        message = t('package:message.download_transport_status.success_to_mail')
                    } else {
                        convertBlobDataToExcel(response.data, fileName)
                    }
                } catch (e) {
                    convertBlobDataToExcel(response.data, fileName)
                }

                notification.success({
                    message,
                })
            })

            reader.readAsText(response.data)
        } catch (error) {
            handleDownloadTransportStatusError(error)
        }

        this.setState({
            downloadingTransportStatus: false,
        })
    }

    render() {
        const { downloadingTransportStatus } = this.state

        return (
            <ListPackage
                {...{
                    ...this.props,
                    defaultFilter: this.getDefaultFilter(),
                    filter: this.getCurrentFilter(),
                    onSearch: this.onSearch.bind(this),
                }}
                downloadingTransportStatus={downloadingTransportStatus}
                onDownloadTransportStatuses={this.handleDownloadTransportStatuses}
            />
        )
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withProperties(withServices(withConsignServices(ListPackageContainer)))))
