import cookies from 'react-cookies'
import moment from 'moment'
import config from '../../config/auth'

class ApiAuth {
    constructor(storageKey = 'auth_token') {
        this.storageKey = storageKey
    }

    getToken() {
        return cookies.load(this.storageKey)
    }

    setToken(token) {
        cookies.save(this.storageKey, token, {
            path: '/',
            expires: config.tokenExpire ? moment().add(config.tokenExpire, 'minutes').toDate() : null,
        })
    }

    removeToken() {
        cookies.remove(this.storageKey, { path: '/' })
    }
}

export default ApiAuth
