import { useQuery } from 'react-query'
import apiService from '../../modules/Customer/apiService'
import { getVar } from '../../system/support/helpers'

export  const useGetSuggestCustomerQuery  = ({ params, enable }) =>{
    return useQuery({
        queryKey: ['customers', params],
        queryFn: async () => {
            const response = await apiService.getSuggests(params)
            return getVar(response, "data", [])
        },
        enabled: enable,
        retry:false,
        refetchOnWindowFocus: false
    })
}