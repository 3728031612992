import { useMutation } from 'react-query'
import apiService from '../../modules/Package/CreatePackageConsignment/apiService'
import { getVar } from '../../system/support/helpers'
import { notification } from 'antd'
import { t } from '../../system/i18n'

export const UseCreatePackageConsignmentMutation = () => {
    return useMutation({
        mutationKey: 'createPackageConsignment',
        mutationFn: async (payload) => {
            const response = await apiService.createPackageConsignment(payload)
            return getVar(response, "data", [])
        },
        onSuccess: () => {
            notification.success({message: t("package_consignment:message.request_create_success")});
        },
        retry: 60,
        retryDelay: 10000,
    })
}