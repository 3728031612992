import React, { Component } from 'react';
import { notification } from 'antd';

import apiService from './../modules/Warehouse/apiService';
import { t } from '../system/i18n';

const withWarehouses = (WrappedComponent, config = {}) => {
    return class extends Component {
        state = {
            warehouses: [],
            loadingWarehouses: false
        };

        getWarehouses = async () => {
            this.setState({
                loadingWarehouses: true
            });
    
            try {
                const { data } = await apiService.fetchSuggestWarehouses();
    
                this.setState({
                    warehouses: data.warehouses.map(warehouse => warehouse.warehouse)
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }
    
            this.setState({
                loadingWarehouses: false
            });
        };

        componentDidMount() {
            if (config && config.getDataWhenMounted) {
                this.getWarehouses();
            }
        }

        render() {
            const { warehouses, loadingWarehouses } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    loadingWarehouses={loadingWarehouses}
                    warehouses={warehouses}
                    getWarehouses={this.getWarehouses}
                />
            );
        }
    };
};

export default withWarehouses;
