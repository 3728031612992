import { withTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import pageService from '../../Common/Page/pageService'
import SelectCustomer from './components/SelectCustomer'
import { Checkbox, Col, Form, Layout, Row } from 'antd'
import PromptLeavePage from '../../DeliveryNote/Create/components/PromptLeavePage'
import TextArea from 'antd/lib/input/TextArea'
import Action from './components/Action'
import NewChooseWarehouse from './components/NewChooseWarehouse'
import FormServices from '../../Service/FormServices/FormServicesContainer'
import withConsignServiceGroups from '../../../hocs/withConsignServiceGroups'
import withServiceGroups from '../../../hocs/withServiceGroups'
import ScanBarcode from './components/ScanBarcode'
import { UseCreatePackageConsignmentMutation } from '../../../hooks/package/useCreatePackageConsignmentMutation'
import { find, includes, map, some } from 'lodash'
import ListTask from './components/ListTask'
import isEmpty from 'lodash/isEmpty'
import { getVar } from '../../../system/support/helpers'

const { Content } = Layout;

const defaultValue = {
    "is_create_bag": false,
    "note": null,
    "id_services": [],
    "parcels": []
}

const CreatePackageConsignmentContainer = React.forwardRef(({ form, t, loadingServiceGroups, loadingConsignServiceGroups, consignServiceGroups }, ref) => {
    const { getFieldDecorator, setFieldsValue,getFieldError, getFieldValue, validateFields } = form;

    const [listTaskId, setListTaskId] = useState([])

    const {mutateAsync, isLoading} = UseCreatePackageConsignmentMutation()
    const serviceError = getFieldError('id_services')
    const services = getFieldValue('id_services')

    const hasConsignWoodService = some(consignServiceGroups,item =>
        item.code === "CONSIGN_WOOD" && item.services.some(service => includes(services, service.id))
    );


    const onReset = () => {
        setFieldsValue(defaultValue);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {

                const payload = {
                    ...values,
                    parcels: map(values.parcels, (parcel, index) => ({
                        barcode: parcel.barcode,
                        weight_net: parcel.weight_net,
                        index: index + 1,
                        ...(parcel.size ? {
                            ...(getVar(parcel, ".size.height") ? { height: parcel.size.height } : {}),
                            ...(getVar(parcel, ".size.width") ? { width: parcel.size.width } : {}),
                            ...(getVar(parcel, ".size.length") ? { length: parcel.size.length } : {}),
                        } : {})
                    })),
                }
                onReset();
                mutateAsync(payload).then((data) => {
                    setListTaskId((prev) => [...prev, getVar(data, 'task._id')])
                })
            }
        });

    };

    useEffect(() => {
        pageService.setTitle(t('package:create.consignment')
            // , <Sound />
        );
    }, []);

    useEffect(() => {
        if (!isLoading) return;

        const beforeUnload = (e: BeforeUnloadEvent) =>{
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [isLoading]);


    return (
        <Layout id={'consignment-container'} className="has-sub-sider a-block flex-grow-1">
            <Content className="form-customer a-sub-main gflex gflex-direction-column" style={{ marginLeft: 0 }}>
                <PromptLeavePage when={isLoading} />

                {!isEmpty(listTaskId) && <ListTask listTaskId={listTaskId} />}

                <div className="block--primary">
                    <div className="title left">
                        <span className="a-text--bold">{t('package_consignment:label.create_order_consignment')}</span>
                    </div>
                    <div className="body min-h-screen mb-32 not-boxshadow">
                        <Row gutter={24}>
                            <Col
                                span={18}
                                xl={{ span: 19 }}
                                xxl={{ span: 19 }}>
                                <Form layout={'vertical'} defaultValue={defaultValue}>
                                    <Row gutter={12}>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 12 }}>
                                            <Form.Item
                                                className={'form__item'}
                                                label={t('package_consignment:label.customer_receiver')}>
                                                {getFieldDecorator('id_customer',{
                                                    rules: [{ required: true, message: t('package_consignment:message.customer_code_required')}],
                                                })(
                                                    <SelectCustomer
                                                        disabled={!!getFieldValue("id_customer")}
                                                        onSelectCustomer={(v) => setFieldsValue({ id_customer: v })}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 12 }}>
                                            <Form.Item
                                                className={'form__item'}
                                                label={t('delivery_note:label.warehouse')}
                                            >
                                                {getFieldDecorator('id_warehouse',{
                                                    rules: [{ required: true, message: t('package_consignment:message.warehouse_required') }],
                                                })(
                                                    <NewChooseWarehouse
                                                        onChange={(v) => setFieldsValue({ id_warehouse: v })}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item className="form__item">
                                                {getFieldDecorator('is_create_bag')(
                                                    <Checkbox checked={getFieldValue('is_create_bag')}>{t('package_consignment:label.complete_package')}</Checkbox>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item className="form__item" label={t('label.note')} labelCol={{ span: 24 }}>
                                                {getFieldDecorator('note')(
                                                    <TextArea
                                                        autosize={{
                                                            minRows: 3,
                                                            maxRows: 3,
                                                        }}
                                                        className="a-input--textarea _note"
                                                        name="note"
                                                        placeholder={t('placeholder.note')}
                                                        spellCheck={false}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item className="a--hidden" labelCol={{ span: 24 }} required>
                                                {getFieldDecorator('parcels', {
                                                    rules: [{ required: true, message: t('package_consignment:message.parcels_required') }],
                                                })(
                                                    <></>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Row>
                                    <ScanBarcode hasConsignWoodService={hasConsignWoodService} error={getFieldError('parcels')} dataTable={getFieldValue('parcels') || []} setDataTable={(v) => setFieldsValue({parcels: v})}/>
                                </Row>

                            </Col>
                            <Col
                                 span={6}
                                 xl={{ span: 5 }}
                                 xxl={{ span: 5 }}
                            >
                                <div className="a-inline-edit service-edit">
                                    <div className="a-inline-edit--input">
                                        {getFieldDecorator('id_services', {
                                            rules: [{ required: true, message: t('package_consignment:message.service_required') }],
                                        })(
                                            <FormServices values={getFieldValue('id_services')} />
                                        )
                                        }
                                    </div>
                                    {serviceError && (<div className="warning_message">{serviceError[0]}</div>)}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>

            <Action loading={loadingServiceGroups || loadingConsignServiceGroups} onSubmit={onSubmit} onReset={onReset}/>
        </Layout>
    );
});

const CreatePackageConsignmentForm = Form.create({ name: 'CreatePackageConsignmentForm' })(withServiceGroups(withConsignServiceGroups(CreatePackageConsignmentContainer)));


export default withTranslation()(CreatePackageConsignmentForm);
