import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchConsignServices } from '../modules/Service/actions';

const mapStateToProps = ({ service }) => {
    const { loadingConsignServices, consignServices } = service;

    return {
        loadingConsignServices,
        consignServices
    };
};

const mapDispatchToProps = {
    fetchConsignServices
};

const withConsignServices = WrappedComponent => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(class extends Component {
        componentDidMount() {
            const { fetchConsignServices, loadingConsignServices } = this.props;

            if (loadingConsignServices) {
                return;
            }

            fetchConsignServices();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    });
};

export default withConsignServices;
