import React, { Component } from 'react';
import { notification } from 'antd';

import apiService from './../modules/ShippingReturnPartners/apiService';
import { t } from '../system/i18n';

const withShippingReturnPartners = (WrappedComponent, config = {}) => {
    return class extends Component {
        state = {
            shippingReturnPartners: [],
            loadingShippingReturnPartners: false
        };

        getShippingReturnPartners = async () => {
            this.setState({
                loadingShippingReturnPartners: true
            });
    
            try {
                const { data } = await apiService.getSuggests(config.params);
    
                this.setState({
                    shippingReturnPartners: data.shipping_return_partners
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }
    
            this.setState({
                loadingShippingReturnPartners: false
            });
        };

        componentDidMount() {
            if (config && config.getDataWhenMounted) {
                this.getShippingReturnPartners();
            }
        }

        render() {
            const { shippingReturnPartners, loadingShippingReturnPartners } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    shippingReturnPartners={shippingReturnPartners}
                    loadingShippingReturnPartners={loadingShippingReturnPartners}
                    getShippingReturnPartners={this.getShippingReturnPartners}
                />
            );
        }
    };
};

export default withShippingReturnPartners;
