import React from 'react'
import PropTypes from 'prop-types'

import {
    PRINT_BAG_STAMP,
    PRINT_BATCH_STAMP,
    PRINT_IFRAME,
    PRINT_OFFICIAL_PACKAGE_STAMP,
    PRINT_PACKAGE_UNOFFICIAL_STAMP,
    TEMPLATE_STAMP,
    TYPE_PRINT,
    PRINT_WAREHOUSE_AREA_STAMP,
    PRINT_9710_STAMP,
} from './../constants'

import PrintBagStamp from './PrintBagStamp'
import PrintIframe from './PrintIframe'
import PrintOfficialPackageStamp from './PrintOfficialPackageStamp'
import PrintPackageUnOfficialStamp from './PrintPackageUnOfficialStamp'
import PrintStampPackage from './PrintStampPackage'
import DetailDeliveryNotePrintContainer from './../../../DeliveryNote/DeliveryNotePrint'
import DeliveryNoteReturnPrint from './../../../DeliveryNote/Return/Print/PrintContainer'
import DeliveryNoteTransportPrint from './../../../DeliveryNote/TransportWarehouse/Print/PrintContainer'
import PrintBatch from './PrintBatchStamp'
import TemplateDefault from './ShippingBagStamp/TemplateDefault'
import { get } from 'lodash'
import PrintStampMultiPackage from './PrintStampMultiPackage'
import DeliveryNotePrintFBUContainer from '../../../DeliveryNote/DeliveryNotePrint/DeliveryNotePrintFBUContainer'
import PrintWarehouseAreaStamp from './PrintWarehouseAreaStamp'
import Print9710PackageStamp from './Print9710PackageStamp'

class PrintStamp extends React.Component {
    componentDidMount() {
        this.initOnAfterPrintEvent()
    }

    componentWillUnmount() {
        window.removeEventListener('afterprint', this.handleAfterPrint)
    }

    initOnAfterPrintEvent = () => {
        window.addEventListener('afterprint', this.handleAfterPrint)
    }

    handleAfterPrint = () => {
        const { clearPrintState, fetchSetting } = this.props

        clearPrintState()
        // fetchSetting();
    }

    print = () => {
        window.print()
    }

    render() {
        const { dataPrint } = this.props
        const { numberStamp, packageData, typePrint, warehouseCode, items } = dataPrint
        switch (typePrint) {
            case TYPE_PRINT.PACKAGE:
                return (
                    <PrintStampPackage
                        title={document.title}
                        warehouseCode={warehouseCode}
                        packageData={packageData}
                        numberStamp={numberStamp}
                        classPrint="stamp-print"
                        onDidMount={this.print}
                    />
                )

            case TYPE_PRINT.MULTI_PACKAGE:
                return (
                    <PrintStampMultiPackage
                        title={document.title}
                        warehouseCode={warehouseCode}
                        packageData={packageData}
                        items={items}
                        classPrint="stamp-print"
                        onDidMount={this.print}
                    />
                )

            case TYPE_PRINT.ISSUE:
                return (
                    <DetailDeliveryNotePrintContainer
                        {...this.props}
                        title={document.title}
                        onDidMount={this.print}
                    />
                )
            case TYPE_PRINT.FBU_STAMP:
                return (
                    <DeliveryNotePrintFBUContainer
                        {...this.props}
                        title={document.title}
                        onDidMount={this.print}
                    />
                )
            case TYPE_PRINT.DELIVERY_NOTE_RETURN: {
                return (
                    <DeliveryNoteReturnPrint
                        {...this.props}
                        title={document.title}
                        onDidMount={this.print}
                    />
                )
            }
            case TYPE_PRINT.DELIVERY_NOTE_TRANSPORT_WAREHOUSE: {
                return (
                    <DeliveryNoteTransportPrint
                        {...this.props}
                        title={document.title}
                        onDidMount={this.print}
                    />
                )
            }
            case PRINT_OFFICIAL_PACKAGE_STAMP:
                return (
                    <PrintOfficialPackageStamp
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            case PRINT_PACKAGE_UNOFFICIAL_STAMP:
                return (
                    <PrintPackageUnOfficialStamp
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            case PRINT_BAG_STAMP:
                const tempCode = get(dataPrint, 'batch_stamp.temp', '')

                if (tempCode === TEMPLATE_STAMP.DCT) {
                    const batchStamp = get(dataPrint, 'batch_stamp', {})
                    return (
                        <TemplateDefault
                            {...dataPrint}
                            onDidMount={this.print}
                            numberStamp={numberStamp}
                            batchStamp={batchStamp}
                        />
                    )
                }

                return (
                    <PrintBagStamp
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            case PRINT_IFRAME: {
                return (
                    <PrintIframe
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            }
            case PRINT_BATCH_STAMP: {
                return (
                    <PrintBatch
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            }
            case PRINT_WAREHOUSE_AREA_STAMP: {
                return (
                    <PrintWarehouseAreaStamp
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            }

            case PRINT_9710_STAMP: {
                return (
                    <Print9710PackageStamp
                        {...dataPrint}
                        onDidMount={this.print}
                    />
                )
            }
            default:
                return null
        }
    }
}

PrintStamp.defaultProps = {
    dataPrint: {},
}

PrintStamp.propTypes = {
    dataPrint: PropTypes.object,
}

export default PrintStamp
