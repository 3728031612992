import { Button, Checkbox, Form, Icon, Input, Modal, notification, Upload } from 'antd'
import clsx from 'clsx'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import { downloadBlobData, isExcelFile } from '../../../../system/support/helpers'
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer'
import SuggestBag from './SuggestBag'
import apiService from '../../../../modules/Package/apiService'
import { get, isEmpty, isNil, replace } from 'lodash'
import SelectAgency from '../Agency/SelectAgency'
import ModalEditTemplateBag from '../ModalEditTemplateBag'
import SelectService from './SelectService'
import SuggestBagList from '../SuggestBagList'
import authService from '../../../Auth/authService'
import permissions from '../../../Auth/permissions'

const { Dragger } = Upload

const UploadForm = ({ form, uploadingCreatePackage, onUploadCreatePackage, renderErrors, errors, hash, setErrors, setHash, notificationErrors }) => {
    const { getFieldDecorator } = form
    const suggestRef = React.useRef(null)
    const [fileList, setFileList] = useState([])
    const [downloadingCreatePackageTemplate, setDownloadingCreatePackageTemplate] = useState(false)
    const [visibleModalEdit, setVisibleModalEdit] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChangeFile = event => {
        const { file, fileList } = event
        setErrors([])
        form.setFieldsValue({
            code_bag: replace(file.name, /\.[^/.]+$/, ''),
        })
        setFileList(fileList.length ? [file] : [])
    }

    const beforeUpload = () => false

    const downloadCreatePackageTemplate = async () => {
        setDownloadingCreatePackageTemplate(true)

        try {
            const response = await apiService.downloadExpeditedProcessingTemplate()
            downloadBlobData(response.data, 'expedited-processing-bag-code', 'xlsx')
        } catch (error) {
            notification.error({
                message: t('package:download_sample_file.failed'),
            })
        }

        setDownloadingCreatePackageTemplate(false)
    }

    const showModal = () => {
        setVisibleModalEdit(true)
    }

    const handleCancel = e => {
        setVisibleModalEdit(false)
    }

    const onChangeCheckbox = (value, name) => {
        form.setFieldsValue({
            [name]: value,
        })
    }

    const onConfirmCreatePackage = () => {
        const isExport = form.getFieldValue('is_export')
        if (!isEmpty(errors) || isExport) {
            Modal.confirm({
                title: t('btn.confirm'),
                content: (
                    <div>
                        {!isEmpty(errors) && <div>{t('package:expedited_package.message.confirm_create_expedited_package')}</div>}
                        {isExport && <div> {t('package:expedited_package.message.confirm_export_after_create')} </div>}
                    </div>
                ),
                onOk: onCreatePackage,
                onCancel() {},
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
            })
        } else {
            onCreatePackage()
        }
    }

    const onCreatePackage = () => {
        setLoading(true)
        const data = {
            ...form.getFieldsValue(),
            id_services: !isNil(form.getFieldValue('id_services')) ? [form.getFieldValue('id_services')] : undefined,
            hash,
        }

        return apiService
            .expeditedProcessingCreatePackage(data)
            .then(result => {
                reset()
                notification.success({ message: t('package:expedited_package.message.create_processing') })
            })
            .catch(err => {
                notificationErrors(err)
            })
            .finally(() => setLoading(false))
    }

    const reset = () => {
        setHash('')
        form.resetFields()
        setFileList([])
        setErrors([])
    }

    const permissionCreateShipment = get(authService.getPartnerConfigSetting('setting', 'ORDER:CREATE_SHIPMENT'), 'value')

    return (
        <div className="a-content--search-header pt-5">
            <div className="gflex gjustify-space-between">
                <Button
                    className="mb-4"
                    loading={downloadingCreatePackageTemplate}
                    onClick={downloadCreatePackageTemplate}>
                    <Icon
                        className={clsx({
                            'ml-3': downloadingCreatePackageTemplate,
                        })}
                        type="download"
                    />
                    {t('package:download_sample_file.main')}
                </Button>

                <Button
                    className="mb-4"
                    onClick={reset}>
                    <Icon type="reload" />
                    {t('btn.reset')}
                </Button>
            </div>

            <Form form={form}>
                <div className="gflex">
                    <Form.Item
                        className="mb-4 width-25-pc mr-4 mb-0"
                        label={'Agency'}>
                        {getFieldDecorator('id_agency', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: 'Agency',
                                    }),
                                },
                            ],
                        })(
                            <SelectAgency
                                placeholder="Agency"
                                disabled={hash}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        className="mb-4 width-25-pc mr-4 mb-0"
                        label={t('package:label.warehouse_input')}>
                        {getFieldDecorator('id_warehouse', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('package:label.warehouse_input'),
                                    }),
                                },
                            ],
                        })(
                            <SelectWarehouse
                                className="_a-select-warehouse-input-package"
                                dropdownClassName="_dropdown_id_warehouse_input"
                                placeholder={t('package:label.warehouse_input')}
                                disabled={hash}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        className="width-25-pc mr-4 mb-0"
                        label={t('bag_template')}>
                        {getFieldDecorator(
                            'id_bag_template',
                            {}
                        )(
                            <SuggestBag
                                ref={suggestRef}
                                disabled={hash}
                            />
                        )}
                    </Form.Item>

                    {form.getFieldValue('id_bag_template') && (
                        <div
                            style={{ margin: 0 }}
                            className="ant-row ant-form-item gflex galign-center">
                            {/* <div
                                className="ant-col ant-form-item-label"
                                style={{ height: 40, width: 12 }}></div> */}
                            <a>
                                <Icon
                                    type="edit"
                                    onClick={showModal}
                                    className="pt-5 pr-5 mt-5"
                                />
                            </a>
                            {visibleModalEdit && (
                                <ModalEditTemplateBag
                                    id={form.getFieldValue('id_bag_template')}
                                    suggestRef={suggestRef}
                                    handleCancel={handleCancel}
                                />
                            )}
                        </div>
                    )}

                    {/* <Form.Item
                        className="width-25-pc mr-4 mb-0"
                        label={t('bag:label.code')}>
                        {getFieldDecorator('code_bag', {
                            rules: [
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('bag:label.code'),
                                    }),
                                },
                            ],
                        })(
                            <Input
                                className="_a-select-code-input-package"
                                dropdownClassName="_dropdown_id_code_input"
                                placeholder={t('bag:placeholder.code')}
                                disabled={hash}
                            />
                        )}
                    </Form.Item> */}
                </div>
                <div className="mb-8 gflex">
                    <span className="mr-4">
                        {getFieldDecorator('create_shipment_order', {
                            initialValue: false,
                            valuePropName: 'checked',
                        })(
                            <>
                                <Checkbox
                                    className="_a-select-code-input-package mr-3"
                                    id="auto_generator_one_step"
                                    onChange={({ target }) => onChangeCheckbox(target.checked, 'create_shipment_order')}
                                    disabled={hash || !permissionCreateShipment}
                                    checked={form.getFieldValue('create_shipment_order')}
                                />
                                <label htmlFor="auto_generator_one_step">{t('bag:label.auto_generator_one_step')}</label>
                            </>
                        )}
                    </span>

                    {form.getFieldValue('create_shipment_order') &&
                        getFieldDecorator(
                            'id_services',
                            {}
                        )(
                            <SelectService
                                types={['consign']}
                                disabled={hash}
                            />
                        )}
                </div>
                <div className="mb-8">
                    <span>
                        {getFieldDecorator('is_export', {
                            initialValue: false,
                            valuePropName: 'checked',
                        })(
                            <>
                                <Checkbox
                                    className="_a-select-code-input-package mr-3"
                                    id="is_export"
                                    onChange={({ target }) => onChangeCheckbox(target.checked, 'is_export')}
                                    disabled={hash}
                                    checked={form.getFieldValue('is_export')}
                                />
                                <label htmlFor="is_export">{t('package:message.export_warehouse_after_create')}</label>
                            </>
                        )}
                    </span>
                </div>
                <div className="mb-8">
                    <span>
                        {getFieldDecorator('is_only_a_bag', {
                            initialValue: false,
                            valuePropName: 'checked',
                        })(
                            <>
                                <Checkbox
                                    className="_a-select-code-input-package mr-3"
                                    id="is_only_a_bag"
                                    onChange={({ target }) => onChangeCheckbox(target.checked, 'is_only_a_bag')}
                                    disabled={!form.getFieldValue('id_bag_template') || hash}
                                    checked={form.getFieldValue('is_only_a_bag')}
                                />
                                <label htmlFor="is_only_a_bag">{t('package:message.create_package_for_case_list_file')}</label>
                            </>
                        )}
                    </span>
                </div>
                <Form.Item style={{ marginBottom: 12 }}>
                    {getFieldDecorator('file', {
                        rules: [
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: 'File',
                                }),
                            },
                            {
                                validator: async (rule, value, callback) => {
                                    if (value) {
                                        const { file, fileList } = value
                                        const { name } = file

                                        if (fileList.length && !isExcelFile(name)) {
                                            throw new Error(
                                                t('validation:mimes', {
                                                    attribute: 'File',
                                                    values: 'xlsx, xls',
                                                })
                                            )
                                        }
                                    }

                                    callback()
                                },
                            },
                        ],
                    })(
                        <Dragger
                            accept=".xlsx, .xls"
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeFile}>
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">{t('dragger_form.hint1')}</p>
                            <p className="ant-upload-hint">{t('dragger_form.hint2')}</p>
                        </Dragger>
                    )}
                </Form.Item>
                {!isEmpty(errors) && (
                    <div className="pt-3">
                        {errors.map((item, index) => {
                            return (
                                <p
                                    key={index}
                                    className="a-text--red">
                                    - {renderErrors(item)}
                                </p>
                            )
                        })}
                    </div>
                )}
                <div className="gflex gjustify-center mt-4">
                    {hash ? (
                        <Button
                            onClick={onConfirmCreatePackage}
                            loading={loading}
                            type="primary"
                            style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', padding: '0 48px', height: '42px' }}>
                            {t('package:create.title')}
                        </Button>
                    ) : (
                        <Button
                            loading={uploadingCreatePackage}
                            onClick={() => onUploadCreatePackage(form)}
                            type="primary">
                            {t('upload')}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )
}

export default Form.create()(UploadForm)
