export default {
    CREATE_SHIPMENT_ORDER_BY_CUSTOMER: 'CONFIG:CREATE_SHIPMENT_ORDER_BY_CUSTOMER', // Quyền tạo đơn hàng vận chuyển ký gửi
    PACKAGE_CREATE_BY_BARCODE: 'PACKAGE:CREATE_BY_BARCODE', // Tạo kiện quét mã
    PACKAGE_VIEW: 'PACKAGE:VIEW', // Xem thông tin kiện
    PACKAGE_ITEM_CHECKING: 'PACKAGE_ITEM:CHECKING', // Kiểm kiện
    PACKAGE_CHANGE_TRANSPORT_STATUS: 'PACKAGE:CHANGE_TRANSPORT_STATUS', // quyền thay đổi trạng thái của kiện
    PACKAGE_LINK_ORDER: 'PACKAGE:LINK_ORDER',
    PACKAGE_UPDATE: 'PACKAGE:UPDATE', // Cập nhật kiện
    PACKAGE_DOWNLOAD_TRANSPORT_STATUS: 'PACKAGE:DOWNLOAD_TRANSPORT_STATUS', // Tải thông tin thời gian trạng thái của kiện
    PACKAGE_CHANGE_MIA_TRANSPORT_STATUS: 'PACKAGE:CHANGE_MIA_TRANSPORT_STATUS', // Chuyển trạng thái của kiện sang thất lạc
    PACKAGE_CHANGE_DAMAGED_STATUS: 'PACKAGE:CHANGE_DAMAGED_STATUS', // Thay đổi trạng thái lỗi của kiện
    PACKAGE_CREATE_COMPLAINT_SELLER: 'PACKAGE:CREATE_COMPLAINT_SELLER', // Tạo khiếu nại người bán
    BARCODE_SCAN: 'BARCODE:SCAN', // Quyền quét mã xuất nhập
    BARCODE_VIEW: 'BARCODE:VIEW', // Xem thông tin mã xuất nhập
    BARCODE_RECEIVE: 'BARCODE:RECEIVE', // Quét nhận hàng
    BARCODE_SCAN_SHIPMENT: 'BARCODE:SCAN_SHIPMENT', // Quét vận chuyển hộ
    BAG_CREATE: 'BAG:CREATE', // Tạo bao
    BAG_VIEW: 'BAG:VIEW', // Xem bao
    PACKAGING_REQUEST_VIEW: 'PACKAGING_REQUEST:VIEW',
    PACKAGING_REQUEST_VIEW_DETAIL: 'PACKAGING_REQUEST:VIEW_DETAIL',
    BAG_VIEW_DETAIL: 'BAG:VIEW_DETAIL',
    BAG_VIEW_PACKING: 'BAG:VIEW_PACKING', // Xem bao cần đóng
    BAG_PACKING: 'BAG:PACKING', //  Đóng bao
    BAG_PACKING_ALL: 'BAG:PACKING_ALL', //  Đóng bao khi bao đã kết thúc
    BAG_UPDATE: 'BAG:UPDATE', // Đóng bao
    BAG_PRINT_BARCODE: 'BAG:PRINT_BARCODE', // In tem
    BAG_UPDATE_ALL: 'BAG:UPDATE_ALL', // Sửa thông tin bao đã đóng
    BAG_CHANGE_TRANSPORT_STATUS: 'BAG:CHANGE_TRANSPORT_STATUS', // Cập nhật trạng thái vận chuyển của bao
    PACKAGE_DELETE: 'PACKAGE:DELETE', // Đóng bao
    DELIVERY_NOTE_CREATE_FOR_CUSTOMER: 'DELIVERY_NOTE:CREATE_FOR_CUSTOMER', // quyen tao phieu xuat cho khach vc
    DELIVERY_NOTE_UPDATE_FOR_CUSTOMER: 'DELIVERY_NOTE:UPDATE_CUSTOMER', // quyen tao phieu xuat cho khach
    DELIVERY_NOTE_EXPORT_FOR_CUSTOMER: 'DELIVERY_NOTE:EXPORT_FOR_CUSTOMER', //Quyền xuất phiếu cho khách hàng
    DELIVERY_NOTE_VIEW_DETAIL: 'DELIVERY_NOTE:VIEW_DETAIL',
    DELIVERY_NOTE_VIEW_LISTING: 'DELIVERY_NOTE:VIEW_LISTING', // quyen xem danh sach phieu xuat
    DELIVERY_NOTE_RETURN_MANAGE: 'DELIVERY_NOTE_RETURN:MANAGE', // Quản lý phiếu trả hàng
    DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE: 'DELIVERY_NOTE_TRANSPORT_WAREHOUSE:MANAGE', // Quản lý phiếu chuyển kho
    PACKAGE_CREATE_ALONE: 'PACKAGE:CREATE_ALONE',
    DELIVERY_REQUEST_VIEW_LISTING: 'DELIVERY_REQUEST:VIEW_LISTING', // quyen xem danh sach yeu cau xuat
    DELIVERY_REQUEST_CANCEL: 'DELIVERY_REQUEST:CANCEL', // quyen huy yeu cau xuat,
    DELIVERY_REQUEST_HANDLE: 'DELIVERY_REQUEST:HANDLE', // quyen xu ly YCG,
    DELIVERY_PARTNER_MANAGE: 'DELIVERY_PARTNER:MANAGE', // quyen quan ly doi tac van chuyen,
    DELIVERY_PARTNER_VIEW_FEE: 'DELIVERY_PARTNER:VIEW_FEE', // quyen xem danh sach phi van chuyen
    DELIVERY_PARTNER_CREATE_FEE: 'DELIVERY_PARTNER:CREATE_FEE', // quyen tao moi phi van chuyen
    DELIVERY_PARTNER_EDIT_FEE: 'DELIVERY_PARTNER:EDIT_FEE', // quyen sua phi van chuyen
    DELIVERY_AGENT_MANAGE: 'DELIVERY_AGENT:MANAGE', // quyen quan ly nhan vien van chuyen,
    CONFIG_WAREHOUSE_STAFF: 'CONFIG:WAREHOUSE_STAFF', // quyen cai dat nhan vien nha kho,
    PROPERTIES_MANAGE: 'PROPERTIES:MANAGE', // quyen cai dat dac tinh,
    CONFIG_SERVICE: 'CONFIG:SERVICE', // quyen cai dat dich vu,
    CONFIG_SAMPLE_BAG: 'BAG_TEMPLATE:MANAGE', // quyen cai dat bao mau,
    CONFIG_BAG_SIZE: 'CONFIG:BAG_SIZE', // quyen cai dat kich thuoc bao,
    CONFIG_AGENCY: 'CONFIG:AGENCY',
    CONFIG_PACKAGE_CODE: 'CONFIG:PACKAGE_CODE',
    WAREHOUSES_MANAGE: 'WAREHOUSES:MANAGE', // quyền quản lý kho
    WAREHOUSES_MANAGE_STAFF: 'WAREHOUSES:MANAGE_STAFF', // quyền quản lý nhan vien kho
    INVENTORY_ALL: 'INVENTORY:ALL', // Quyền đối soát kiểm kê
    INVENTORY_RECEIVE_ALL: 'INVENTORY:RECEIVE_ALL', // Quyền kiểm kê nhận hàng
    CONFIG_WAREHOUSE: 'CONFIG:WAREHOUSE', // quyen cai dat nha kho,
    CONFIG_GENERAL: 'CONFIG:GENERAL', // quyen cai dat nha kho,
    CHAT_WITH_CUSTOMER: 'CHAT:WITH_CUSTOMER', // quyen trao đổi với khách hàng,,
    FOLLOWING_VIEW_ALL: 'FOLLOWING:VIEW_ALL', // quyen xem toan bo luu y cua he thong
    ATTENTION_MANAGE: 'ATTENTION:MANAGE', // Quản lý lưu ý hàng hoá
    ATTENTION_CUSTOMER_MANAGE: 'ATTENTION_CUSTOMER:MANAGE', // Quản lý lưu ý khách hàng
    EXPORT_BAGS_LIST: 'EXPORT:BAGS_LIST', //quyền xuất danh sách bao,
    EXPORT_PACKAGES_LIST: 'EXPORT:PACKAGES_LIST', //quyền xuất danh sách kiện,
    EXPORT_MANIFEST: 'EXPORT:MANIFEST', //quyền xuất file manifest,
    IMPORT_MANIFEST: 'IMPORT:MANIFEST', //quyền import file manifest,
    EXPORT_PACKAGES_LIST_IN_BAG: 'EXPORT:PACKAGES_LIST_IN_BAG', // quyền xuất danh sách kiện trong bao,
    DELIVERY_NOTE_CREATE_FOR_GLOBAL_SHIPPING_PARTNER: 'DELIVERY_NOTE:CREATE_FOR_GLOBAL_SHIPPING_PARTNER', // Tạo phiếu xuất cho đối tác vận chuyển quốc tế
    DELIVERY_NOTE_UPDATE_FOR_GLOBAL_SHIPPING_PARTNER: 'DELIVERY_NOTE:UPDATE_FOR_GLOBAL_SHIPPING_PARTNER', // Cập nhật phiếu xuất cho đối tác vận chuyển quốc tế
    DELIVERY_NOTE_GLOBAL_SHIPPING_PARTNER_VIEW_LISTING: 'DELIVERY_NOTE:GLOBAL_SHIPPING_PARTNER_VIEW_LISTING', // Xem danh sách phiếu xuất cho đối tác vận chuyển quốc tế,
    DELIVERY_NOTE_GLOBAL_SHIPPING_PARTNER_VIEW_DETAIL: 'DELIVERY_NOTE:GLOBAL_SHIPPING_PARTNER_VIEW_DETAIL', // Xem chi tiết phiếu xuất cho đối tác vận chuyển quốc tế,
    DELIVERY_NOTE_EXPORT_FOR_GLOBAL_SHIPPING_PARTNER: 'DELIVERY_NOTE:EXPORT_FOR_GLOBAL_SHIPPING_PARTNER', // Xuất phiếu cho đối tác vận chuyển quốc tế,
    BAG_AREA_MANAGE: 'BAG_AREA:MANAGE', // Quản lý khu vực bao
    NOTICE_STAGE_MANAGE: 'NOTICE_STAGE:MANAGE', // Quản lý khâu cảnh báo
    SHIPPING_FEE_MANAGE: 'SHIPPING_FEE:MANAGE', // Quản lý giá vốn vận chuyển
    STORAGE_FEE_MANAGE: 'STORAGE_FEE:MANAGE', // Quản lý lưu kho
    STATISTICS_MANAGE: 'STATISTICS:MANAGE', // Quản lý thống kê
    DOMESTIC_DELIVERY_PARTNER_MANAGE: 'DOMESTIC_DELIVERY_PARTNER:MANAGE', // Quản lý đối tác vận chuyển nội địa
    BAG_NOTICE_CUSTOMER: 'BAG:NOTICE_CUSTOMER',
    DELIVERY_NOTE_DOWNLOAD_LISTING: 'DELIVERY_NOTE:DOWNLOAD_LISTING',
    PACKAGE_EXPEDITED_PROCESSING: 'PACKAGE:EXPEDITED_PROCESSING', //  cần quyền Vận hành siêu nhanh
    PACKAGE_EXPEDITED_PROCESSING_CREATE: 'PACKAGE:EXPEDITED_PROCESSING_CREATE', //  Tạo kiện vận hành siêu nhanh
    CONFIG_EXPEDITED_OPERATION: 'CONFIG:EXPEDITED_OPERATION', // cấu hình vận hành siêu nhanh
    PACKAGE_EXPEDITED_PROCESSING_DISTRIBUTE: 'PACKAGE:EXPEDITED_PROCESSING_DISTRIBUTE',
    LASTMILE_BAG_TEMPLATE_MANAGE: 'LASTMILE_BAG_TEMPLATE:MANAGE',
    BAG_LASTMILE_CREATE: 'BAG_LASTMILE:CREATE',
    BAG_LASTMILE_PACKING: 'BAG_LASTMILE:PACKING',
    DELIVERY_REQUEST_VIEW_PROCESSING: 'DELIVERY_REQUEST:VIEW_PROCESSING',
    CONFIG_SHOW_AGENCY_ALIAS: 'CONFIG:SHOW_AGENCY_ALIAS',
    FINANCE_ADJUST_VIEW: 'FINANCE_ADJUST:VIEW', //Quyền xem giao dịch điều chỉnh tài chính agency
    FINANCE_ADJUST_CREATE: 'FINANCE_ADJUST:CREATE', //Quyền tạo giao dịch điều chỉnh tài chính agency,
    CUSTOMER_VIEW_INFO: 'CUSTOMER:VIEW_INFO', //Quyền xem username/mã khách hàng
    RETURN_NOTE_MANAGE: 'RETURN_NOTE:MANAGE', //Quyền thao tác với phiếu nhập hoàn
    DELIVERY_NOTE_CONFIRM: 'DELIVERY_NOTE:CONFIRM', //Quyền xác nhận phiếu xuất thành công
    PACKAGE_STORE_PACKAGE: 'PACKAGE:STORE_PACKAGE', // Quyền đưa kiện lên vị trí kho
    LASTMILE_SHIPMENT_AUTOMATION_CREATE: 'LASTMILE-SHIPMENT:AUTOMATION-CREATE', // Quyền tự kết đơn và in tem tự động khi tạo phiếu xuất khách hàng,
    LASTMILE_ORDER_VIEW: 'LASTMILE_ORDER:VIEW', //quyền xem danh sách vận đơn lastmile
}
