import React, {Component} from 'react';
import {Icon, Layout, Menu, Tooltip} from 'antd';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import {MENU_CONFIG} from './../../MenuConfig/constants';
import {CODE_PARTNER} from './../../../Auth/constants';
import PERMISSION from './../../../Auth/permissions';
import {Link} from './../../../../system/routing';

/*Icon list menu*/
import {ReactComponent as IconBag} from './../../../../resources/images/Menu/bag.svg';
import {ReactComponent as IconOperate} from './../../../../resources/images/Menu/barcode.svg';
import {ReactComponent as IconPackage} from './../../../../resources/images/Menu/package.svg';
import {ReactComponent as IconExport} from './../../../../resources/images/Menu/setting.svg';

import { canGlobalSetting } from '../../../../system/support/helpers';

/**/
const {Sider} = Layout;
const {SubMenu} = Menu;

const getMenuConfigPath = permissions => {
    if (!Array.isArray(permissions)) {
        permissions = [];
    }
    ;

    let configPath = '';
    let menuConfigByPermission = MENU_CONFIG.filter(config => {
        let configPermissions = config.permission;

        if (!Array.isArray(config.permission)) {
            configPermissions = [config.permission];
        }

        return configPermissions.some(configPermission => permissions.includes(configPermission));
    });

    if (menuConfigByPermission.length > 0) {
        configPath = menuConfigByPermission[0].path;
    }
    return configPath;
};

const MenuConfigs = [
    {
        icon: <IconOperate className="icon"/>,
        title: "menu.operator",
        key: "menu_operator",
        className: "a-sider-main--sub-menu _menu_operator",
        permission: [PERMISSION.BARCODE_SCAN, PERMISSION.BARCODE_VIEW, PERMISSION.PACKAGE_STORE_PACKAGE],
        subMenu: [
            {
                key: "barcode/scan",
                title: "menu.scan_barcode",
                permission: [PERMISSION.BARCODE_SCAN],
                className: "a-sider-main--sub-menu _menu_scan_barcode",
            },
            {
                key: "barcode/scanning-history",
                title: "menu.scanning_history",
                permission: [PERMISSION.BARCODE_VIEW],
                className: "a-sider-main--sub-menu _menu_scanning_history",
            },
            {
                key: "warehouse/scan-to-stowing",
                title: "menu.scan-to-stowing",
                permission: [PERMISSION.PACKAGE_STORE_PACKAGE],
                className: "a-sider-main--sub-menu _menu_scan_to_stowing",
            },
        ]
    },
    {
        icon: <IconExport className="icon"/>,
        title: "menu.package",
        key: "menu_package",
        className: "a-sider-main--sub-menu _menu_package",
        permission: [PERMISSION.PACKAGE_CREATE_BY_BARCODE, PERMISSION.PACKAGE_VIEW, PERMISSION.PACKAGE_ITEM_CHECKING, PERMISSION.PACKAGE_CHANGE_TRANSPORT_STATUS],
        subMenu: [
            {
                key: "packages/create",
                title: "menu.create_package",
                permission: [PERMISSION.PACKAGE_CREATE_BY_BARCODE],
                className: "a-sider-main--sub-menu _menu_create_package",
            },
            {
                key: "packages/consignment/create",
                title: "menu.create_package_consignment_by_order",
                permission: [PERMISSION.PACKAGE_CREATE_BY_BARCODE],
                className: "a-sider-main--sub-menu _menu_create_package",
                setting: [PERMISSION.CREATE_SHIPMENT_ORDER_BY_CUSTOMER]
            },
            {
                key: "packages",
                title: "menu.packages",
                permission: [PERMISSION.PACKAGE_VIEW],
                className: "a-sider-main--sub-menu _menu_packages",
            },
            {
                key: "packages/checking",
                title: "menu.packages_checking",
                permission: [PERMISSION.PACKAGE_ITEM_CHECKING],
                className: "a-sider-main--sub-menu _menu_packages",
            },
            {
                key: "packages/upload",
                title: "menu.upload_package_list",
                permission: [PERMISSION.PACKAGE_CHANGE_TRANSPORT_STATUS],
                className: "a-sider-main--sub-menu _menu_packages",
            },
            {
                key: "packages/expedited-processing",
                title: "menu.expedited_processing",
                permission: [PERMISSION.PACKAGE_EXPEDITED_PROCESSING],
                className: "a-sider-main--sub-menu _menu_packages",
                setting: [PERMISSION.CONFIG_EXPEDITED_OPERATION]
            },
            {
                key: "packages/waiting-delivery",
                title: "menu.waiting_delivery",
                permission: [PERMISSION.DELIVERY_REQUEST_VIEW_PROCESSING],
                className: "a-sider-main--sub-menu _menu_packages",
            },
            {
                key: "packages/scan-split-package",
                title: "menu.split_package",
                permission: [PERMISSION.PACKAGE_CREATE_BY_BARCODE],
                className: "a-sider-main--sub-menu _menu_packages",
            }
        ]
    },
    {
        icon: <IconBag className="icon"/>,
        title: "menu.bag",
        key: "menu_bag",
        className: "a-sider-main--sub-menu _menu_bag",
        permission: [PERMISSION.BAG_CREATE, PERMISSION.BAG_VIEW, PERMISSION.BAG_VIEW_PACKING, PERMISSION.IMPORT_MANIFEST],
        subMenu: [
            {
                key: "bags/create",
                title: "menu.create_bag",
                permission: [PERMISSION.BAG_CREATE],
                className: "a-sider-main--sub-menu _menu_create_bag",
            },
            {
                key: "bags",
                title: "menu.list_bag",
                permission: [PERMISSION.BAG_VIEW],
                className: "a-sider-main--sub-menu _menu_bags",
            },
            {
                key: "bags/request-list",
                title: "menu.bagging_request_list",
                permission: [PERMISSION.PACKAGING_REQUEST_VIEW],
                className: "a-sider-main--sub-menu _menu_bags_request_view",
            },
            {
                key: "bags/list-pack",
                permission: [PERMISSION.BAG_VIEW_PACKING],
                title: "menu.list_bag_pack",
            },
            {
                key: "bags/manifest",
                permission: [PERMISSION.IMPORT_MANIFEST],
                title: "menu.import_manifest",
            },
        ]
    },
    {
        icon: <IconBag className="icon"/>,
        title: "menu.bag_lastmile",
        key: "menu_bag_lastmile",
        className: "a-sider-main--sub-menu _menu_bag",
        subMenu: [
            {
                key: "bags-lastmile/create",
                title: "menu.create_bag",
                // permission: [PERMISSION.BAG_CREATE],
                className: "a-sider-main--sub-menu _menu_create_bag_lastmile",
            },
           
        ]
    },
    {
        icon: <IconPackage className="icon"/>,
        title: "menu.delivery_requests",
        key: "menu_delivery_requests",
        className: "a-sider-main--sub-menu _delivery_request",
        permission: [PERMISSION.DELIVERY_REQUEST_VIEW_LISTING],
        subMenu: [
            {
                key: "delivery-requests",
                title: "menu.list_delivery_request",
                permission: [PERMISSION.DELIVERY_REQUEST_VIEW_LISTING],
                className: "a-sider-main--sub-menu _menu_list_delivery_request",
            },
        ]
    },
    {
        icon: (
            <IconPackage className="icon"/>
        ),
        title: 'to_deliver',
        key: 'to_deliver',
        className: 'a-sider-main--sub-menu _to-delivery',
        permission: [
            PERMISSION.DELIVERY_NOTE_CREATE_FOR_CUSTOMER,
            PERMISSION.DELIVERY_NOTE_VIEW_LISTING,
            PERMISSION.DELIVERY_NOTE_RETURN_MANAGE,
            PERMISSION.DELIVERY_NOTE_GLOBAL_SHIPPING_PARTNER_VIEW_LISTING,
            PERMISSION.DELIVERY_NOTE_GLOBAL_SHIPPING_PARTNER_VIEW_DETAIL,
            PERMISSION.DELIVERY_NOTE_CREATE_FOR_GLOBAL_SHIPPING_PARTNER,
            PERMISSION.DELIVERY_NOTE_UPDATE_FOR_GLOBAL_SHIPPING_PARTNER,
            PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE
        ],
        subMenu: [
            {
                key: 'delivery-notes/customer/create',
                title: 'create_customer_delivery_note',
                permission: [PERMISSION.DELIVERY_NOTE_CREATE_FOR_CUSTOMER],
                className: 'a-sider-main--sub-menu _menu-create-customer-delivery-note',
            },
            {
                key: 'delivery-notes/customer',
                title: 'customer_delivery_note_list',
                permission: [PERMISSION.DELIVERY_NOTE_VIEW_LISTING],
                className: "a-sider-main--sub-menu _menu-customer-delivery-note-list",
            },
            {
                key: 'delivery-notes/return/create',
                title: 'create_delivery_note_return',
                permission: [PERMISSION.DELIVERY_NOTE_RETURN_MANAGE],
                className: 'a-sider-main--sub-menu _menu-create-return-delivery-note',
            },
            {
                key: 'delivery-notes/return',
                title: 'delivery_note_return_list',
                permission: [PERMISSION.DELIVERY_NOTE_RETURN_MANAGE],
                className: "a-sider-main--sub-menu _menu-return-delivery-note-list",
            },
            {
                key: 'delivery-notes/transport-warehouse/create',
                title: 'create_transport_warehouse_delivery_note',
                permission: [PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE],
                className: 'a-sider-main--sub-menu _menu-create-transfer-warehouse-delivery-note',
            },
            {
                key: 'delivery-notes/transport-warehouse',
                title: 'menu.transport_warehouse_delivery_note_list',
                permission: [PERMISSION.DELIVERY_NOTE_TRANSPORT_WAREHOUSE_MANAGE],
                className: "a-sider-main--sub-menu _menu-transfer-warehouse-delivery-note-list",
            }
        ]
    },
    {
        icon: (
            <IconPackage className="icon"/>
        ),
        title: 'bill_of_lading',
        key: 'bill_of_lading',
        className: 'a-sider-main--sub-menu _bill-of-lading',
        permission: [
            PERMISSION.LASTMILE_ORDER_VIEW,
        ],
        subMenu: [
            {
                key: "lastmile-order",
                title: "menu.lastmile_order_list",
                permission: [PERMISSION.LASTMILE_ORDER_VIEW,],
                className: "a-sider-main--sub-menu _menu-lastmile-order-list",
            },
        ]
    },
    {
        icon: (
            <IconPackage className="icon"/>
        ),
        title: 'to_import',
        key: 'to_import',
        className: 'a-sider-main--sub-menu _to-import',
        permission: [
            PERMISSION.RETURN_NOTE_MANAGE,
        ],
        subMenu: [
            {
                key: 'return-goods/create',
                title: 'menu.create_return_goods',
                permission: [PERMISSION.RETURN_NOTE_MANAGE],
                className: 'a-sider-main--sub-menu _menu-create-return-goods',
            },
            {
                key: "return-goods",
                title: "menu.return_goods_list",
                permission: [PERMISSION.RETURN_NOTE_MANAGE],
                className: "a-sider-main--sub-menu _menu-return-goods-list",
            },
        ]
    },
    {
        icon: <IconPackage className="icon"/>,
        title: "menu.inventory",
        key: "menu_inventory",
        className: "a-sider-main--sub-menu _inventory",
        permission: [PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL],
        subMenu: [
            {
                key: "inventory/create",
                title: "menu.create_inventory",
                permission: [PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL],
                className: "a-sider-main--sub-menu _menu_create_inventory",
            },
            {
                key: "inventory/",
                title: "menu.list_inventory",
                permission: [PERMISSION.INVENTORY_ALL, PERMISSION.INVENTORY_RECEIVE_ALL],
                className: "a-sider-main--sub-menu _menu_list_inventory",
            },
        ]
    },
    {
        className: 'a-sider-main--sub-menu _goods-note',
        icon: (
            <IconPackage className="icon"/>
        ),
        key: 'menu_goods_note',
        subMenu: [{
            className: 'a-sider-main--sub-menu _menu-goods-note-list',
            key: 'notes',
            title: 'goods_note_list'
        }],
        title: 'goods_note',
        permission: [PERMISSION.ATTENTION_MANAGE, PERMISSION.ATTENTION_CUSTOMER_MANAGE]
    },
    {
        icon: <Icon type="bar-chart"/>,
        title: "statistic",
        key: "statistic",
        path: "/statistics",
        className: "a-sider-main--item-only _menu_statistics",
        type: "single",
        permission: [PERMISSION.STATISTICS_MANAGE]
    },
    {
        icon: <Icon type="file-sync" />,
        title: "agency_finance",
        key: "agency_finance",
        path: "/agency_finance",
        className: "a-sider-main--item-only _menu_agency_finance",
        type: "single",
        permission: [PERMISSION.FINANCE_ADJUST_VIEW]
    },
    {
        icon: <IconExport className="icon"/>,
        title: "menu.config",
        key: "menu_config",
        path: getMenuConfigPath,
        className: "a-sider-main--item-only _menu_config",
        type: "single"
    }
];

class SiderX extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = (collapsed) => {
        this.setState({collapsed});
    };

    handleClick = () => {
        this.props.clearPrintState();
    };

    getMenuActive() {
        let {menuActive} = this.props;
        if (menuActive.includes("config/")) {
            return "menu_config";
        }

        return menuActive;
    }

    render() {
        let {permissions, partner, t, setting } = this.props;
        let menuActive = this.getMenuActive();
        let partner_logo = lodash.get(lodash.find(partner, partnerData => partnerData.code === CODE_PARTNER.LOGO), 'value');
        return (
            <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                className="a-sider-main _slider"
            >
                <div className="a-logo _logo">
                    <img
                        alt=""
                        className="logo-collapse"
                        src={partner_logo ? partner_logo : require('./../../../../resources/images/logowhite.svg')}
                    />
                    <img
                        alt=""
                        className="logo-large"
                        src={partner_logo ? partner_logo : require('./../../../../resources/images/logowhite.svg')}
                    />
                </div>
                <Menu
                    className="a-sider-main__menu _sider_menu"
                    defaultSelectedKeys={[menuActive]}
                    selectedKeys={[menuActive]}
                    onClick={this.handleClick} mode="inline"
                >
                    {MenuConfigs.map((menu) => {
                        if (lodash.isNil(menu.permission) || permissions.some(permission => menu.permission.indexOf(permission) >= 0 && (lodash.isNil(menu.setting) || canGlobalSetting(setting, menu.setting)) ) ) {
                            return menu.subMenu ? (
                                <SubMenu
                                    key={menu.key}
                                    title={
                                        <span className="group-title">
                                            {menu.icon}
                                            <span className="title">{t(menu.title)}</span>
                                        </span>
                                    }
                                    className={menu.className}
                                >
                                    {menu.subMenu.map(subMenu => lodash.isNil(subMenu.permission) || permissions.some(permission => subMenu.permission.indexOf(permission) >= 0 && (lodash.isNil(subMenu.setting) || canGlobalSetting(setting, subMenu.setting))) ? (
                                        <Menu.Item
                                            className={subMenu.className}
                                            key={subMenu.key}
                                        >
                                            <Tooltip overlayClassName="pointer-events-none" title={t(subMenu.title)}>
                                                <Link href={`/${subMenu.key}`}>{t(subMenu.title)}</Link>
                                            </Tooltip>
                                        </Menu.Item>
                                    ) : null)}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={menu.key} className={menu.className}>
                                    <Link href={lodash.isFunction(menu.path) ? menu.path(permissions) : menu.path}
                                          className="group-item">
                                        <div className="icon">
                                            {menu.icon}
                                        </div>

                                        <span className="title">{t(menu.title)}</span>
                                    </Link>
                                </Menu.Item>
                            )
                        }

                        return null;
                    })}
                </Menu>
            </Sider>
        )
    }
}

SiderX.defaultProps = {
    permissions: [],
    menuActive: '',
};

SiderX.propTypes = {
    permissions: PropTypes.array,
    menuActive: PropTypes.string,
};

export default withTranslation()(SiderX);
