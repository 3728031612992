import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchServiceGroups } from '../modules/Service/actions';
import { getActiveServiceGroups, getState } from '../modules/Service/selectors';

const mapStateToProps = state => {
    return {
        loadingServiceGroups: getState(state, 'loadingServiceGroups'),
        serviceGroups: getActiveServiceGroups(state)
    };
};

const mapDispatchToProps = {
    fetchServiceGroups
};

const withServiceGroups = WrappedComponent => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(class extends Component {
        getServiceGroups = () => {
            const { loadingServiceGroups, serviceGroups, fetchServiceGroups } = this.props;

            if (loadingServiceGroups || (serviceGroups && serviceGroups.length)) {
                return;
            }

            fetchServiceGroups();
        };

        componentDidMount() {
            this.getServiceGroups();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    });
};

export default withServiceGroups;
