import React, { Component } from 'react';
import { notification } from 'antd';

import apiService from './../modules/Bag/apiService';
import { t } from '../system/i18n';

const withBagTemplates = (WrappedComponent, config = {}) => {
    return class extends Component {
        state = {
            bagTemplates: [],
            loadingBagTemplates: false
        };

        getBagTemplates = async () => {
            this.setState({
                loadingBagTemplates: true
            });
    
            try {
                const response = await apiService.getTemplateBags();
    
                this.setState({
                    bagTemplates: response.data.bag_templates
                });
            } catch (error) {
                notification.error({
                    message: t('message.server_error')
                });
            }
    
            this.setState({
                loadingBagTemplates: false
            });
        };

        componentDidMount() {
            if (config && config.getDataWhenMounted) {
                this.getBagTemplates();
            }
        }

        render() {
            const { bagTemplates, loadingBagTemplates } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    bagTemplates={bagTemplates}
                    loadingBagTemplates={loadingBagTemplates}
                    getBagTemplates={this.getBagTemplates}
                />
            );
        }
    };
};

export default withBagTemplates;
