import {takeLatest} from 'redux-saga/effects';

import * as constants from './constants';
import * as saga from './saga';
import * as ACTION_BAG_PACKING from './../Bag/BagPackingAction/constants';
import * as ACTION_BAG_PACKING_LASTMILE from './../Bag/BagPackingLastmile/constants';
import * as ACTION_DELIVERY_NOTE from './../DeliveryNote/DeliveryNoteBarcode/constants';
import * as ACTION_INVENTORY_BY_SCAN from './../Inventory/InventoryBarcode/constants';
import * as ACTION_PACKAGE_BY_SCAN from './../Package/constants';
import {CHECKING_PACKAGE, UPDATE_RECEIVE_QUANTITY} from './../Package/CheckingPackage/constants';
import {LINK_ORDER_PACKAGE} from './../Package/LinkOrderPackage/constants';
import * as ACTION_SCAN_BARCODE from './../ScanBarcode/ScanBarcodeForm/constants';

export default function* () {
	yield takeLatest(constants.SOUND_CONSTANT.SUCCESS, saga.success_sound);
	yield takeLatest(constants.SOUND_CONSTANT.ERROR, saga.error_sound);
	yield takeLatest(constants.SOUND_CONSTANT.WARNING, saga.warning_sound);
	yield takeLatest(constants.SOUND_CONSTANT.NORMAL, saga.normalSound);
	yield takeLatest([
		ACTION_DELIVERY_NOTE.ADD_BARCODE,
		ACTION_DELIVERY_NOTE.SCAN_BARCODE.SUCCESS,
		ACTION_DELIVERY_NOTE.SCAN_BARCODE.FAILED,
		ACTION_BAG_PACKING.PACKING_PACKAGE.SUCCESS,
		ACTION_BAG_PACKING.PACKING_PACKAGE.FAILED,
        ACTION_BAG_PACKING_LASTMILE.PACKING_PACKAGE_LASTMILE.SUCCESS,
		ACTION_BAG_PACKING_LASTMILE.PACKING_PACKAGE_LASTMILE.FAILED,
		ACTION_SCAN_BARCODE.SCAN_BARCODE_FORM.SUCCESS,
		ACTION_SCAN_BARCODE.SCAN_BARCODE_FORM.FAILED,
		ACTION_SCAN_BARCODE.TRANSPORT_WAREHOUSE.FAILED,
		ACTION_SCAN_BARCODE.SCAN_RECEIVED.SUCCESS,
		ACTION_SCAN_BARCODE.SCAN_RECEIVED.FAILED,
		ACTION_PACKAGE_BY_SCAN.CREATE_PACKAGE_BY_SCAN.SUCCESS,
		ACTION_INVENTORY_BY_SCAN.SCAN_PACKAGE.SUCCESS,
		ACTION_INVENTORY_BY_SCAN.SCAN_PACKAGE.FAILED,
		ACTION_INVENTORY_BY_SCAN.SCAN_TRACKING_BILL.SUCCESS,
		ACTION_INVENTORY_BY_SCAN.SCAN_TRACKING_BILL.FAILED,
		ACTION_PACKAGE_BY_SCAN.CREATE_PACKAGE_BY_SCAN.FAILED,
		CHECKING_PACKAGE.SUCCESS,
		CHECKING_PACKAGE.FAILED,
		UPDATE_RECEIVE_QUANTITY.SUCCESS,
		LINK_ORDER_PACKAGE.SUCCESS,
	], saga.play_sound);
}
