import { notification } from 'antd'
import { get, isNil, omit, isUndefined } from 'lodash'
import React, { useState } from 'react'
import { t } from '../../../../system/i18n'
import apiService from '../../apiService'
import UploadForm from './UploadForm'

const CreatePackages = () => {
    const [uploadingCreatePackage, setUploadingCreatePackage] = useState(false)
    const [errors, setErrors] = useState([])
    const [hash, setHash] = useState('')

    const uploadCreatePackage = form => {
        form.validateFields((error, values) => {
            if (error) {
                return
            }
            if(isUndefined(values.id_bag_template)) {
                delete values.id_bag_template;
            }

            doUploadCreatePackage({
                ...values,
                create_shipment_order: get(values, 'create_shipment_order', false),
                file: values.file.file,
            })
        })
    }

    const doUploadCreatePackage = async data => {
        setUploadingCreatePackage(true)

        try {
            const formData = new FormData()

            if (data) {
                Object.keys(omit(data, 'id_services')).forEach(key => formData.append(key, omit(data, 'id_services')[key]))

                if(!isNil(get(data, 'id_services'))){
                    formData.append('id_services[]', get(data, 'id_services'))
                }
                
            }

            const response = await apiService.importCreatePackage(formData)
            setErrors(get(response, 'data.errors', []))
            setHash(get(response, 'data.hash', ''))
        } catch (error) {
            notificationErrors(error)
        }
        setUploadingCreatePackage(false)
    }

    const notificationErrors = error => {
        const code = get(error, 'response.data.code')
        const data = get(error, 'response.data.data')

        if (code === 'INPUT_INVALID') {
            Object.entries(data).forEach(([key, value]) => {
                switch (key) {
                    case 'id_bag_template':
                        notification.error({
                            message: t(`package:expedited_package.message.${key}.${value.message}`, {
                                attribute: t(`package:expedited_package.label.${value.code}`),
                            }),
                        })

                        break
                    case 'id_agency':
                        notification.error({
                            message: t(`package:expedited_package.message.${key}.${Object.keys(value)[0]}`),
                        })

                        break
                    default:
                        notification.error({
                            message: t(`package:expedited_package.message.${Object.keys(value)[0]}`, {
                                attribute: t(`package:expedited_package.label.${key}`),
                            }),
                        })
                        break
                }
            })
        } else {
            notification.error({ message: t('message.server_error') })
        }
    }

    const renderErrors = line => {

        if(get(line, 'code','') === 'file' && get(line, 'message','') === "empty"){
            return t(`package:expedited_package.message.file_empty`)
        }

        const error = get(line,'items', []).map(item => {
            return t(`package:expedited_package.message.${item.message}`, { attribute: t(`package:label.${item.code}`) })
        })

        return t(`package:expedited_package.message.line`, {
            line: line.line,
            content: error.join(", "),
        })
    }

    return (
        <>
            <UploadForm
                uploadingCreatePackage={uploadingCreatePackage}
                onUploadCreatePackage={uploadCreatePackage}
                errors={errors}
                renderErrors={renderErrors}
                hash={hash}
                setHash={setHash}
                setErrors={setErrors}
                notificationErrors={notificationErrors}
            />
        </>
    )
}

export default CreatePackages
