import React, { useEffect, useState } from 'react'
import authService from '../../../Auth/authService'
import { t } from '../../../../system/i18n'
import { Select } from 'antd'
import { useGetListWarehouseQuery } from '../../../../hooks/warehouse/useGetListWarehouseQuery'
import { getVar } from '../../../../system/support/helpers'
import { find, map } from 'lodash'

const NewChooseWarehouse = React.forwardRef(({onChange}, ref) => {
    const [value, setValue] = useState(null)
    const { data, isLoading} = useGetListWarehouseQuery({
        active: true,
        warehouse_permission: true
    })

    const keyLocalStorage = 'warehouse_' + authService.user('id')
    const warehouseIdInLocal = parseInt(localStorage.getItem(keyLocalStorage))
    const warehouses = map(getVar(data, "warehouses", []), warehouse => ({
        ...getVar(warehouse, "warehouse", {}),
    }))

    const warehouseInLocal = find(warehouses, { id: warehouseIdInLocal })

    useEffect(() => {
        if (warehouseInLocal && !value) {
            setValue(warehouseInLocal.id)
            onChange(warehouseInLocal.id)
        }
    },[warehouseInLocal])

    const formattedSelectProps = {
        className: `a-select a-select--w-100 _select_warehouse`,
        placeholder: t('placeholder.choose_warehouse'),
        showSearch: true,
        loading: isLoading,
        disabled: isLoading,
        optionFilterProp: "children",
        filterOption: (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        onSelect: (v) => {
            setValue(v)
            onChange(v)
            localStorage.setItem(keyLocalStorage, v);
        },
        value,
    }

    return (
        <Select {...formattedSelectProps} >
            {map(warehouses, warehouse => (
                <Select.Option
                    className="_select_warehouse__item"
                    key={warehouse.id}
                    value={warehouse.id}
                >
                    {warehouse.code}
                </Select.Option>
            ))}
        </Select>
    );
})

export default NewChooseWarehouse;