import common from './common.json';
import validation from './validation.json';
import auth from './auth.json';
import layout from "./layout.json";
import packageLang from './package.json';
import bag from './bag.json';
import bag_size from './bag_size.json';
import barcode from './barcode.json';
import location from './location.json';
import scan from './scan.json';
import service from './service.json';
import shipping_partner from './shipping_partner.json';
import shipping_user from './shipping_user.json';
import warehouse_staff from './warehouse_staff';
import delivery_note from "./delivery_note.json"
import delivery_request from "./delivery_request.json"
import inventory from "./inventory.json"
import language from "./language.json"
import config from "./config.json"
import template_bag from './template_bag.json';
import note from './note.json';
import agency from './agency.json';
import official_form from './official_form';
import log from './log.json';
import shipping_fee from './shipping_fee.json';
import bag_area from './bag_area.json';
import notice from './notice.json';
import warehouse from './warehouse.json';
import storage_fee from './storage_fee.json';
import return_goods from './return_goods.json';
import lastmile_order from './lastmile_order.json';
import package_consignment from './package_consignment.json';

export default {
    scan,
    location,
    layout,
    common,
    validation,
    language,
    auth,
    package: packageLang,
    barcode,
    bag,
    service,
    delivery_note,
    delivery_request,
    bag_size,
    shipping_partner,
    shipping_user,
    warehouse_staff,
	config,
	template_bag,
    inventory,
    note,
    agency,
    official_form,
    log,
    shipping_fee,
    bag_area,
    notice,
    warehouse,
    storage_fee,
    return_goods,
    lastmile_order,
    package_consignment,
};
