import React, { Fragment } from 'react';
import { notification } from 'antd';
import lodash, { filter, get, isEmpty, map } from 'lodash';
import queryString from 'query-string';

import { t, trans } from './../../system/i18n';
import { getState } from '../store';

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue);

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => lodash.get(window.APP_CONFIG, param, defaultValue);

/**
 * Lấy value của param trong object (nếu value la undefined || null thì sẽ trả về defaultValue)
 *
 * @param object
 * @param param
 * @param defaultValue
 * @return {*}
 */
export const getVar = (object, param, defaultValue = null) => {
    let value = lodash.get(object, param);

    return (value === undefined || value === null || value === '') ? defaultValue : value;
};

export const formatWeight = (value, formatType = 1) => {
    if (value) {
        value = lodash.round(value, 4);
    }

    switch (formatType) {
        case 1:
            return `${value} kg`;
        case 2:
            return `${value} (kg)`;
        default:
            return value;
    }
};

export const formatVolume = (value, formatType = 1) => {
    if(value) {
        const cm3 = lodash.round(value * 1000000, 3);
        switch (formatType) {
            case 1:
                return <span>{cm3} cm<sup>3</sup></span>;
            case 2:
                return <span>{cm3} (cm<sup>3</sup>)</span>;
            default:
                return cm3;
        }
    }
};

export const getFilterFromLocation = (location) => {
    return queryString.parse(location.search, {arrayFormat: 'bracket'});
};

export const getFileTypeExtension = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.'))
};
export const getStringToArrayExtension = (accept) => {
    return lodash.split(lodash.replace(accept, " ", ""), ",")
};

export const checkFileExtension = (fileName, accept) => {
    if (lodash.isNil(fileName) || lodash.isUndefined(fileName)) {
        return false
    }
    fileName = fileName.toString();

    if (Array.isArray(accept)) {
        return accept.includes(getFileTypeExtension(fileName));
    }

    return getStringToArrayExtension(accept).includes(getFileTypeExtension(fileName))
};

export const isExcelFile = fileName => checkFileExtension(fileName, ['.xlsx', '.xls']);

export const convertToBoolean = (value) => {
    return value === 1 || value === true;
};

export const downloadBlobData = (data, fileName, fileType) => {
    if (!data) {
        return;
    }

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.setAttribute('href', downloadUrl);
    link.setAttribute('download', `${fileName}.${fileType}`);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export const convertBlobDataToCsv = (data, fileName) => {
    downloadBlobData(data, fileName, 'csv');
};

export const convertBlobDataToExcel = (data, fileName) => {
    downloadBlobData(data, fileName, 'xlsx');
};

export const downloadCsvData = (data, fileName, isUTF8 = true) => {
    if (isUTF8) {
        data = `data:text/csv;charset=utf-8,\ufeff${data}`;
    } else {
        data = `data:text/csv;charset=utf-8,${data}`;
    }

    const encodedUri = encodeURI(data);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const handleBlobErrorResponse = (error, callback) => {
    try {
        const reader = new FileReader();

        reader.addEventListener('loadend', callback);
        reader.readAsText(lodash.get(error, 'response.data'));
    } catch (error) {
    }
};

export const getFormErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        const attribute = t(`label.${param}`);
        const messages = translateFormErrors(attribute, rules);

        return lodash.first(lodash.values(messages));
    });
};

export const getErrors = error => {
    return lodash.mapValues(error.data, (rules, param) => {
        const attribute = t(`label.${param}`);
        const messages = translateErrors(attribute, rules);

        return lodash.first(lodash.values(messages));
    });
};

export const translateFormErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        const messageKey = `validation:form.rules.${rule}`;
        const message = t(messageKey, {
            ...data,
            attribute
        });

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

export const translateErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        const messageKey = `validation:${rule}`;
        const message = t(messageKey, {
            ...data,
            attribute
        });

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

export const isEmptyObject = object => {
    return lodash.isObject(object) && !Object.keys(lodash.omitBy(object, value => lodash.isNil(value))).length;
};

export const convertUndefinedPropertyToNull = object => {
    return convertUndefinedPropertyTo(object);
};

export const convertUndefinedPropertyTo = (object, to = null) => {
    const newObject = {};

    Object.keys(object).forEach(key => {
        if (lodash.isUndefined(object[key])) {
            newObject[key] = to;
        } else {
            newObject[key] = object[key];
        }
    });

    return newObject;
};

export const removeNilProperty = object => lodash.pickBy(object, lodash.identity);

// merge array1 to array2 by key
export const mergeObjectArrayByKey = (array1 = [], array2 = [], key) => {
    const result = [];

    for (const item of array2) {
        const duplicateItem = array1.find(compareItem => compareItem[key] === item[key]);

        if (item) {
            result.push({
                ...duplicateItem,
                ...item
            });
        } else {
            result.push(item);
        }
    }

    return result;
};

export const isExportToMail = data => lodash.get(data, 'message') === 'send_mail';

export const isVietnamesePhoneNumber = number => /(03|05|07|08|09|01[2689])+([0-9]{8})\b/.test(number);

export const notifyErrorFromServer = (error, defaultMessage, prefix) => {
    let message = defaultMessage;

    const errors = lodash.get(error, 'response.data.data');

    if (lodash.isObject(errors) && errors && Object.keys(errors).length) {
        const firstErrorKey = Object.keys(errors)[0];
        const translatedErrors = [];

        for (const error of Object.keys(errors[firstErrorKey])) {

            translatedErrors.push(trans(`${prefix}.${firstErrorKey}.${error}`));
        }

        message = translatedErrors.map((translatedError, index) => (
            <Fragment key={index}>
                {translatedError}
                {index !== translatedErrors.length - 1 && <br />}
            </Fragment>   
        ));
    }

    notification.error({
        message
    });
};


export const canGlobalSetting = (listSetting ,settings)=> {
    settings = lodash.isArray(settings) ? settings : [settings];
    let userGlobalSetting = map(filter(listSetting, 'value'), 'code') || [];
    if (lodash.get(userGlobalSetting, 0, '') === '*') {
        return true;
    }

    return lodash.every(settings, setting => userGlobalSetting.includes(setting));
}


export const showAliasAgency = (agencySetting,globalSetting, settings) => {

    const inAgencySettings = filter(agencySetting, item => item.code === settings)

    if(!isEmpty(inAgencySettings)){
        const settingValue = get(inAgencySettings, '0.value')
        return settingValue
    }else{
        const inGlobalSettings = filter(globalSetting, item => item.code === settings)
        const settingValue = get(inGlobalSettings, '0.value')
        return settingValue
    }
}

export const formatPrice = (value, key = ',') => {
    if (value === null || value === undefined) return ''
    value = value.toString()
    if (key === ',') value = value.replace(/[.]/g, '')
    if (value.indexOf(key) > 0) {
        let temp = value.split(key)
        return `${temp[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${key}${temp[1]}`
    } else {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
}

