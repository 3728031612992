import { useQuery } from 'react-query'
import { getVar } from '../../system/support/helpers'
import apiService from '../../modules/Warehouse/apiService'

export const useGetListWarehouseQuery  = (params) =>{
    return useQuery({
        queryKey: ['warehouse.list', params],
        queryFn: async () => {
            const response = await apiService.fetchSuggestWarehouses(params)
            return getVar(response, "data", [])
        },
        refetchOnWindowFocus: false,
    })
}

