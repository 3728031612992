import React, { useRef, useState } from 'react'
import { t } from '../../../../system/i18n'
import { Select } from 'antd'
import { getVar } from '../../../../system/support/helpers'
import lodash, { debounce, map } from 'lodash'
import { useGetSuggestCustomerQuery } from '../../../../hooks/customer/useGetSuggestCustomerQuery'

const SelectCustomer = React.forwardRef(({ disabled, onSelectCustomer}, ref) => {
    const [search, setSearch] = useState('')

    const inputRef = useRef()

    const { data, isLoading} = useGetSuggestCustomerQuery({
        params: {
            username: search,
            per_page: 100,
        },
        enable: !!search}
    )

    const customers = getVar(data, "customers", []);
    const debouncedSearch = debounce(setSearch, 500)

    return (
        <Select
            className="a-select"
            disabled={disabled}
            ref={inputRef}
            loading={isLoading}
            filterOption={(input, option) => {
                return (option.props.label ? option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) || (option.props.code ? option.props.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
            }}
            name="customer"
            optionFilterProp="children"
            optionLabelProp="label"
            placeholder={t('delivery_note:placeholder.choose_customer')}
            showSearch
            onBlur={(e) => e}
            onSearch={(v) => debouncedSearch(v)}
            onSelect={(v) => {
                onSelectCustomer(v)
                inputRef.current.blur()
            }}

        >
            {map(customers, customer => (
                <Select.Option
                    code={getVar(customer, 'customer.code', '--')}
                    key={lodash.get(customer, 'customer.id')}
                    label={`${getVar(customer, 'customer.username', '--')} (${getVar(customer, 'agency.code') || getVar(customer, 'customer.agency.code') || '--'})`}
                    value={lodash.get(customer, 'customer.id')}
                >
                    <span className="a-text--black">{`${getVar(customer, 'customer.username', '--')} (${getVar(customer, 'agency.code') || getVar(customer, 'customer.agency.code') || '--'})`}</span>
                    <br />
                    <span className="a-text--fz-12 a-text--gray-70">{getVar(customer, 'customer.code', '--')}</span>
                </Select.Option>
            ))}
        </Select>
    )

})

export default SelectCustomer
