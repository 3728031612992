import { Button, Col, Divider, Form, Icon, Input, InputNumber, Row, Table } from 'antd'
import { t } from '../../../../system/i18n'
import React from 'react'
import { getVar } from '../../../../system/support/helpers'
import { filter } from 'lodash'
import isEmpty from 'lodash/isEmpty'

const ScanBarcode = ({form, setDataTable,error, dataTable, hasConsignWoodService}) => {
    const { validateFields, getFieldDecorator, resetFields, setFieldsValue , getFieldValue} = form;

    const onSubmit = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                setDataTable(
                    [
                        ...dataTable,
                        {
                            index: values.barcode,
                            barcode: values.barcode,
                            weight_net: values.weight_net,
                            size: {
                                length: getVar(values, "size.length"),
                                width: getVar(values, "size.width"),
                                height: getVar(values, "size.height")
                            }
                        }
                    ]
                )
                resetFields();
            }
        });
    }


    const columns = [
        {
            title: `${t('package_consignment:column.numerical')}`,
            key: 'index',
            render: (_, __,index) => {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: `${t('package_consignment:column.package_code')}`,
            dataIndex: 'barcode',
            key: 'barcode',
        },
        {
            title: `${t('package_consignment:column.weight')} (kg)`,
            dataIndex: 'weight_net',
            key: 'weight_net',
        },
        {
            title: `${t('package_consignment:column.size')} (${t('package_consignment:column.length')} x ${t('package_consignment:column.width')} x ${t('package_consignment:column.height')})`,
            dataIndex: 'size',
            key: 'size',
            render: (_, record) => {
                return (
                    <span>{getVar(record, "size.length", "---")} x {getVar(record, "size.width", "---")} x {getVar(record, "size.height", "---")}</span>
                )
            }
        },
        {
            title: `${t('package_consignment:column.action')}`,
            key: 'action',
            render: (text, record) => {
                const filtered = filter(dataTable,(item) => getVar(record, "index") !== getVar(item, "index"));

                return (
                        <Icon type="delete" className={'icon-delete'} onClick={() => {
                            setDataTable(filtered);
                        }}/>
                )
            },
        },
    ];

    return(
        <div className={'flex flex-col gap-24'}>
            <Divider dashed>{t("barcode:scanning_history.column.CREATE_PACKAGE")}</Divider>
            <Form layout={'vertical'} className="a-block" onSubmit={onSubmit}>
                <Row gutter={16}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        <Form.Item className={'form__item'} label={t('package_consignment:label.scan_barcode')}>
                            {getFieldDecorator('barcode', {
                                rules: [{ required: true, message: t('package_consignment:placeholder.scan_barcode') }],
                            })(
                               <Input placeholder={t('package_consignment:placeholder.scan_barcode')} />
                            )}
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        <Form.Item className={'form__item'} label={t('package_consignment:column.weight')}>
                            {getFieldDecorator('weight_net', {
                                rules: [{ required: true, message: t('package_consignment:message.weight_required') }],
                            })(
                                <InputNumber min={0} className={'gw-100'} placeholder={t('package_consignment:placeholder.weight')} />
                            )}
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                    >
                        <Form.Item required={hasConsignWoodService} className={'form__item'} label={t('package_consignment:column.size')}>
                            {getFieldDecorator('size', {
                                rules: [{
                                    validator(_, value) {
                                        if(!hasConsignWoodService) return Promise.resolve()
                                        if (!value || !value.length || !value.width || !value.height) {
                                            return Promise.reject(new Error(t('package_consignment:message.size_required')))
                                        }
                                        return Promise.resolve()
                                    },
                                }],
                            })(
                                <Input.Group>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <InputNumber min={0} className={'gw-100'} value={getVar(getFieldValue('size'), "length")} placeholder={t('package_consignment:placeholder.length')} onChange={(value) => setFieldsValue({
                                                size: {
                                                    ...getFieldValue('size'),
                                                    length: value
                                                }})} />
                                        </Col>

                                        <Col span={8}>
                                            <InputNumber min={0} className={'gw-100'} value={getVar(getFieldValue('size'), "width")} placeholder={t('package_consignment:placeholder.width')} onChange={(value) => setFieldsValue({
                                                size: {
                                                    ...getFieldValue('size'),
                                                    width: value
                                                }})}/>
                                        </Col>

                                        <Col span={8}>
                                            <InputNumber min={0} className={'gw-100'} value={getVar(getFieldValue('size'), "height")} placeholder={t('package_consignment:placeholder.height')} onChange={(value) => setFieldsValue({
                                                size: {
                                                    ...getFieldValue('size'),
                                                    height: value
                                                }})}/>
                                        </Col>
                                    </Row>
                                </Input.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <strong>
                    <i>* {t('package_consignment:note.size')}</i>
                </strong>

                <div className={'flex flex-col gjustify-end'}>
                    <div className={'ml-auto'}>
                        <Button
                            className="a-btn mr-2  _btn-save"
                            size="large"
                            type={'ghost'}
                            onClick={() => resetFields("size")}
                        >
                            {t('package_consignment:btn.delete_size')}
                        </Button>

                        <Button
                            className="a-btn a-btn--primary mr-2  _btn-save"
                            size="large"
                            htmlType={'submit'}
                        >
                            {t('package_consignment:btn.create_package')}
                        </Button>
                    </div>
                </div>

            </Form>
            {!isEmpty(error) && <p className={'warning_message'}>{getVar(error, '0')}</p>}
            <Table bordered dataSource={dataTable} columns={columns} pagination={true}/>
        </div>
    )
}

const ScanBarCodeForm = Form.create({ name: 'ScanBarCodeForm' })(ScanBarcode);

export default ScanBarCodeForm;