import React from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import lodash from 'lodash';
import {Button, Col, DatePicker, Form, Icon, Input, Row, Select} from 'antd';
import {PACKAGE_ACTION} from '../../constants';
import FormProcessing from '../../../Common/components/FormProcessing';
import SelectWarehouse from '../../../Warehouse/SelectWarehouse/SelectWarehouseContainer';
import moment from 'moment';
import {STATUS_TRANSPORT_PACKAGE} from "../../ChangePackageTransportStatus/constants";
import SelectProperties from "../../../Property/FormMultiProperties/FormMultiPropertiesContainer";
import {converValue2Int} from './../services';

import InputNumber from './../../../Common/components/InputNumber';
import ServicesSelectContainer from '../../../Common/components/ServicesSelect/ServicesSelectContainer';
import SelectAgency from './../../../Setting/Agency/SelectAgency';
import Link from './../../../../system/routing/Link';
import { ELEMENT } from '../../CreatePackageViaScan/services/inputFocus/constants';
import authService from '../../../Auth/authService';
import permissions from '../../../Auth/permissions';

const Option = Select.Option;
const Search = Input.Search;
const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
const sixMonthsAgo = moment(currentDate).subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss');

class FormSearch extends FormProcessing {
    constructor(props) {
        super(props);

        this.defaultInput = {
            id_warehouse_input: undefined,
            id_warehouse_current: undefined,
            id_warehouse_destination: undefined,
            id_current_warehouse: '',
            'created_at[from]': sixMonthsAgo,
            'created_at[to]': undefined,
            'order_linked_at[from]': undefined,
            'order_linked_at[to]': undefined,
            'weight_net[from]': undefined,
            'weight_net[to]': undefined,
            status_warehouse: undefined,
            status_transports: [],
            code: '',
            username: '',
            properties: [],
            services: [],
            non_services: [],
            id_agency: '',
            is_checked: undefined,
            'length_of_transport_status[]': {
                state: undefined,
                compare: undefined,
                day: undefined
            },
            orthers: []
        };

        this.state = {
            collapsed: Object.keys(lodash.omit(lodash.pickBy(props.input, lodash.identity), ['page', 'sort_by', 'sort_direction', 'i', 'is_shipment', 'is_deleted', 'is_damage', 'is_not_in_bag'])).length ? false : true,
            input: {...this.defaultInput},
            inputVersion: 0,
            changedParams: [],
            validateStatuses: {
                'length_of_transport_status[]': undefined
            },
            helps: {
                'length_of_transport_status[]': undefined
            }
        };
    }

    onSubmit = (event) => {
        event.preventDefault();

        const {input} = this.state;
        const {loading, onSearch} = this.props;

        if (!loading) {
            onSearch({
                ...input,
                page: 1
            });
        }
    }

    onChangeTime = (field, value) => {
        this.changeInput({
            [field]: value ? value.format('YYYY-MM-DD HH:mm:ss') : ''
        });
    };

    onReset = event => {
        event.preventDefault();
        const {input, loading, onSearch} = this.props;

        if (!loading) {
            const newInput = {
                ...this.defaultInput,
                is_shipment: input.is_shipment,
                is_not_in_bag: input.is_not_in_bag
            };

            this.changeInput(newInput);
            onSearch(newInput);
        }
    };

    onChangeMultiSelect(name, value) {
        this.changeInput({[name]: value});
    }

    componentDidUpdate() {
        const {input, validateStatuses} = this.state;
        const {t} = this.props;

        if (input['length_of_transport_status[]'] &&
            Object.keys(input['length_of_transport_status[]']).length &&
            (!input['length_of_transport_status[]'].state ||
                (!input['length_of_transport_status[]'].time_from && !input['length_of_transport_status[]'].time_to))
        ) {
            if (validateStatuses['length_of_transport_status[]'] !== 'error') {
                this.setState({
                    validateStatuses: {
                        'length_of_transport_status[]': 'error'
                    },
                    helps: {
                        'length_of_transport_status[]': t('validation:value_invalid', {
                            attribute: t('package:label.length_of_transport_status')
                        })
                    }
                });
            }
        } else {
            if (!lodash.isNil(validateStatuses['length_of_transport_status[]'])) {
                this.setState({
                    validateStatuses: {
                        'length_of_transport_status[]': undefined
                    },
                    helps: {
                        'length_of_transport_status[]': undefined
                    }
                });
            }
        }
    }

    getPackagesInWarehouseUrl = () => {
        const {inputVersion} = this.props;
        const params = [`status_warehouse=${PACKAGE_ACTION.IN}`];

        lodash.values(STATUS_TRANSPORT_PACKAGE)
            .filter(status => ![STATUS_TRANSPORT_PACKAGE.MIA, STATUS_TRANSPORT_PACKAGE.RETURNED, STATUS_TRANSPORT_PACKAGE.INACTIVE].includes(status))
            .forEach(status => {
                params.push(`status_transports[]=${status}`);
            });

        return `/packages?i=${inputVersion + 1}&${params.join('&')}`;
    };

    toggleCollapseSearch = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    };

    toggleCollapseSearch = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    };

    renderCollapsedForm = (input) => {
        const {collapsed} = this.state;
        const {t} = this.props;
        let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        return (
            <Row
                className={clsx({
                    'a-hidden': !collapsed
                })}
                gutter={{lg: 12, xl: 24}}
                type="flex"
            >
                <Col lg={6} xl={6} xxl={4}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t("package:label.code_search")}
                        labelCol={{span: 24}}>
                        <Search
                            value={input.code || ''}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            placeholder={t("package:placeholder.code_search")}
                            className="a-input--search _packages_search"
                        />
                    </Form.Item>
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <Form.Item
                        className="a-form__item search-content item--special"
                        label={t("package:label.delivery_note_transport_warehouse_code")}
                        labelCol={{span: 24}}>
                        <Search
                            value={input.delivery_note_transport_warehouse_code || ''}
                            onChange={this.onChangeInput.bind(this, 'delivery_note_transport_warehouse_code')}
                            placeholder={t("package:placeholder.delivery_note_transport_warehouse_code")}
                            className="a-input--search _packages_search"
                        />
                    </Form.Item>
                </Col>
                
                <Col lg={4} xl={3} xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("package:label.warehouse_input")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-input-package"
                            dropdownClassName="_dropdown_id_warehouse_input"
                            placeholder={t('package:label.warehouse_input')}
                            value={input.id_warehouse_input}
                            onChange={id_warehouse_input => this.changeInput({id_warehouse_input})}>
                        </SelectWarehouse>
                    </Form.Item>
                </Col>
                <Col lg={4} xl={3} xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("package:label.warehouse_current")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-current-package"
                            dropdownClassName="_dropdown_id_warehouse_current"
                            placeholder={t('package:label.warehouse_current')}
                            value={input.id_warehouse_current}
                            onChange={id_warehouse_current => this.changeInput({id_warehouse_current})}>
                        </SelectWarehouse>
                    </Form.Item>
                </Col>
                <Col lg={4} xl={3} xxl={2}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("package:label.warehouse_destination")}
                        labelCol={{span: 24}}>
                        <SelectWarehouse
                            allowClear
                            className="a-select a-select--search-content _a-select-warehouse-destination-package"
                            dropdownClassName="_dropdown_id_warehouse_destination"
                            isEmpty
                            placeholder={t('package:label.warehouse_destination')}
                            value={input.id_warehouse_destination}
                            onChange={id_warehouse_destination => this.changeInput({id_warehouse_destination})}>
                        </SelectWarehouse>
                    </Form.Item>
                </Col>
                <Col lg={6} xl={4} xxl={3}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t("package:label.status_warehouse")}
                        labelCol={{span: 24}}>
                        <Select allowClear
                                className="a-select a-select--search-content _a-select-status-warehouse-package"
                                dropdownClassName="_dropdown_status_warehouse"
                                value={input.status_warehouse}
                                showSearch={true}
                                optionFilterProp="children"
                                placeholder={t('package:label.status_warehouse')}
                                onChange={status_warehouse => this.changeInput({status_warehouse})}>
                            {lodash.values(PACKAGE_ACTION).map(action => (
                                <Option className={`_a-select-status-warehouse-${action}`}
                                        key={action}
                                        value={action}>{t('package:action.' + action)}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={6} xl={5} xxl={3}>
                    <Form.Item
                        className="a-form__item search-content"
                        label={t('package:label.status_transport')}
                        labelCol={{span: 24}}
                    >
                        <Select
                            allowClear
                            className="a-select a-select--search-content _a-select-status-transport-package"
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={t('package:label.status_transport')}
                            showSearch={true}
                            value={input.status_transports}
                            onChange={status_transports => this.changeInput({status_transports})}
                        >
                            {lodash.values(STATUS_TRANSPORT_PACKAGE).map(action => action !== STATUS_TRANSPORT_PACKAGE.CIRCULATE && (
                                <Option
                                    className={`_a-select-status-transport-${action}`}
                                    key={action}
                                    value={action}
                                >
                                    {t(`package:status_transport.${action}`)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={10} xxl={8}>
                    <Form.Item className="a-form__item search-content" label={t('package:label.created_at')}>
                        <Row gutter={4}>
                            <Col span={12}>
                                <DatePicker
                                    className="width-100-pc _created-at-from"
                                    locale={locale}
                                    placeholder={t('label.time_start')}
                                    format="HH:mm:ss DD/MM/YYYY"
                                    showTime={{
                                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                                    }}
                                    value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                    onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePicker
                                    className="width-100-pc _created-at-to"
                                    locale={locale}
                                    placeholder={t('label.time_end')}
                                    format="HH:mm:ss DD/MM/YYYY"
                                    showTime={{
                                        defaultValue: moment('23:59:59', 'HH:mm:ss')
                                    }}
                                    value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                    onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col lg={4}>
                    <Form.Item
                        className="a-form__item search-content"
                        label="Agency"
                        labelCol={{span: 24}}>
                        <SelectAgency
                            allowClear
                            placeholder="Agency"
                            value={input.id_agency}
                            onChange={agencyId => this.changeInput({
                                id_agency: agencyId
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col lg={8}>
                    <Form.Item
                        labelCol={{span: 24}}
                        className="a-form__item search-content"
                        label={t('package:label.weight') + ' (' + (configPackageUnitWeight === 'g' ?  `${configPackageUnitWeight} x 100` : configPackageUnitWeight )+  ')'}
                    >
                        <InputNumber
                            name={ELEMENT.PACKAGE_NET_WEIGHT}
                            autoComplete="off"
                            precision={2}
                            min={0}
                            value={input['weight_net[from]'] || ''}
                            placeholder={t('package:label.weight_from')}
                            onChange={value => this.changeInput({'weight_net[from]': value})}
                            className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                        />

                        <InputNumber
                            className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                            style={{marginLeft: '4px', paddingLeft: '5px', paddingRight: '5px'}}
                            name={ELEMENT.PACKAGE_NET_WEIGHT}
                            autoComplete="off"
                            precision={2}
                            min={0}
                            value={input['weight_net[to]'] || ''}
                            placeholder={t('package:label.weight_to')}
                            onChange={value => this.changeInput({'weight_net[to]': value})}
                        />

                    </Form.Item>
                </Col>
            </Row>
        );
    };

    renderExpandedForm = input => {
        const {collapsed, helps, validateStatuses} = this.state;
        const {consignServices, services, t} = this.props;
        let configPackageUnitWeight = lodash.get(authService.getPartnerConfigSetting('setting', 'PACKAGE:UNIT_WEIGHT'), 'value', 'g') || 'g';
        return (
            <>
                <Row
                    className={clsx({
                        'a-hidden': collapsed
                    })}
                    gutter={{lg: 12, xl: 24}}
                    type="flex"
                >
                    <Col lg={6} xl={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content item--special"
                            label={t("package:label.code_search")}
                            labelCol={{span: 24}}>
                            <Search
                                value={input.code || ''}
                                onChange={this.onChangeInput.bind(this, 'code')}
                                placeholder={t("package:placeholder.code_search")}
                                className="a-input--search _packages_search"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6} xl={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content item--special"
                            label={t("package:label.delivery_note_transport_warehouse_code")}
                            labelCol={{span: 24}}>
                            <Search
                                value={input.delivery_note_transport_warehouse_code || ''}
                                onChange={this.onChangeInput.bind(this, 'delivery_note_transport_warehouse_code')}
                                placeholder={t("package:placeholder.delivery_note_transport_warehouse_code")}
                                className="a-input--search _packages_search"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={4} xl={3} xxl={2}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.warehouse_input")}
                            labelCol={{span: 24}}>
                            <SelectWarehouse
                                allowClear
                                className="a-select a-select--search-content _a-select-warehouse-input-package"
                                dropdownClassName="_dropdown_id_warehouse_input"
                                placeholder={t('package:label.warehouse_input')}
                                value={input.id_warehouse_input}
                                onChange={id_warehouse_input => this.changeInput({id_warehouse_input})}>
                            </SelectWarehouse>
                        </Form.Item>
                    </Col>
                    <Col lg={4} xl={3} xxl={2}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.warehouse_current")}
                            labelCol={{span: 24}}>
                            <SelectWarehouse
                                allowClear
                                className="a-select a-select--search-content _a-select-warehouse-current-package"
                                dropdownClassName="_dropdown_id_warehouse_current"
                                placeholder={t('package:label.warehouse_current')}
                                value={input.id_warehouse_current}
                                onChange={id_warehouse_current => this.changeInput({id_warehouse_current})}>
                            </SelectWarehouse>
                        </Form.Item>
                    </Col>
                    <Col lg={4} xl={3} xxl={2}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.warehouse_destination")}
                            labelCol={{span: 24}}>
                            <SelectWarehouse
                                allowClear
                                className="a-select a-select--search-content _a-select-warehouse-destination-package"
                                dropdownClassName="_dropdown_id_warehouse_destination"
                                isEmpty
                                placeholder={t('package:label.warehouse_destination')}
                                value={input.id_warehouse_destination}
                                onChange={id_warehouse_destination => this.changeInput({id_warehouse_destination})}>
                            </SelectWarehouse>
                        </Form.Item>
                    </Col>
                    <Col lg={6} xl={4} xxl={3}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.status_warehouse")}
                            labelCol={{span: 24}}>
                            <Select allowClear
                                    className="a-select a-select--search-content _a-select-status-warehouse-package"
                                    dropdownClassName="_dropdown_status_warehouse"
                                    value={input.status_warehouse}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    placeholder={t('package:label.status_warehouse')}
                                    onChange={status_warehouse => this.changeInput({status_warehouse})}>
                                {lodash.values(PACKAGE_ACTION).map(action => (
                                    <Option className={`_a-select-status-warehouse-${action}`}
                                            key={action}
                                            value={action}>{t('package:action.' + action)}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} xl={5} xxl={3}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t('package:label.status_transport')}
                            labelCol={{span: 24}}
                        >
                            <Select
                                allowClear
                                className="a-select a-select--search-content _a-select-status-transport-package"
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder={t('package:label.status_transport')}
                                showSearch={true}
                                value={input.status_transports}
                                onChange={status_transports => this.changeInput({status_transports})}
                            >
                                {lodash.values(STATUS_TRANSPORT_PACKAGE).map(action => (
                                    <Option
                                        className={`_a-select-status-transport-${action}`}
                                        key={action}
                                        value={action}
                                    >
                                        {t(`package:status_transport.${action}`)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={10} xxl={8}>
                        <Form.Item className="a-form__item search-content" label={t('package:label.created_at')}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[from]'] ? moment(input['created_at[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[from]')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _created-at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['created_at[to]'] ? moment(input['created_at[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'created_at[to]')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label="Agency"
                            labelCol={{span: 24}}>
                            <SelectAgency
                                allowClear
                                placeholder="Agency"
                                value={input.id_agency}
                                onChange={agencyId => this.changeInput({
                                    id_agency: agencyId
                                })}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={10} xxl={8}>
                        <Form.Item className="a-form__item search-content" label={t('package:label.order_linked_at')}>
                            <Row gutter={4}>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _order-linked-at-from"
                                        locale={locale}
                                        placeholder={t('label.time_start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['order_linked_at[from]'] ? moment(input['order_linked_at[from]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'order_linked_at[from]')}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        className="width-100-pc _order_linked_at-to"
                                        locale={locale}
                                        placeholder={t('label.time_end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['order_linked_at[to]'] ? moment(input['order_linked_at[to]']) : null}
                                        onChange={this.onChangeTime.bind(this, 'order_linked_at[to]')}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col lg={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("bag:label.properties")}
                            labelCol={{span: 24}}>
                            <SelectProperties
                                placeholder={t("common:label.all")}
                                className="a-select a-select--search-content _properties_muliti"
                                dropdownClassName="_dropdown_properties"
                                values={input.properties || []}
                                onChange={this.onChangeMultiSelect.bind(this, "properties")}>
                            </SelectProperties>
                        </Form.Item>
                    </Col>
                    <Col lg={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("bag:label.services")}
                            labelCol={{span: 24}}>
                            <ServicesSelectContainer
                                allowClear
                                className="_services"
                                consignServices={consignServices}
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder={t('label.all')}
                                services={services}
                                showSearch
                                value={input.services}
                                onChange={this.onChangeMultiSelect.bind(this, 'services')}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6} xl={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("bag:label.non_services")}
                            labelCol={{span: 24}}>
                            <ServicesSelectContainer
                                allowClear
                                className="_non-services"
                                consignServices={consignServices}
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder={t('label.all')}
                                services={services}
                                showSearch
                                value={input.non_services}
                                onChange={this.onChangeMultiSelect.bind(this, 'non_services')}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col lg={6} xl={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.customer_username")}
                            labelCol={{span: 24}}>
                            <Search
                                value={input.username || ''}
                                onChange={this.onChangeInput.bind(this, 'username')}
                                placeholder={t("package:placeholder.customer_username")}
                                className="a-input--search _username_search"
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col lg={8} xl={8}>
                        <Form.Item
                            labelCol={{span: 24}}
                            className="a-form__item search-content"
                            label={t('package:label.weight') + ' (' + (configPackageUnitWeight === 'g' ?  `${configPackageUnitWeight} x 100` : configPackageUnitWeight )+  ')'}
                        >
                            <InputNumber
                                className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                                name={ELEMENT.PACKAGE_NET_WEIGHT}
                                autoComplete="off"
                                precision={2}
                                min={0}
                                value={input['weight_net[from]'] || ''}
                                placeholder={t('package:label.weight_from')}
                                onChange={value => this.changeInput({'weight_net[from]': value})}
                            />
                            <InputNumber
                                style={{paddingLeft: "2px", paddingRight: "2px"}}
                                className="ant-col ant-col-11 mr-2 pr-4 pl-2"
                                name={ELEMENT.PACKAGE_NET_WEIGHT}
                                autoComplete="off"
                                precision={2}
                                min={0}
                                value={input['weight_net[to]'] || ''}
                                onChange={value => this.changeInput({'weight_net[to]': value})}
                                placeholder={t('package:label.weight_to')}
                            />

                        </Form.Item>

                    </Col>
                </Row>

                <Row
                    className={clsx({
                        'a-hidden': collapsed
                    })}
                    gutter={{lg: 12, xl: 24}}
                >
                    <Col>
                        <Form.Item
                            className="a-form__item search-content"
                            help={helps['length_of_transport_status[]']}
                            label={t('package:label.length_of_transport_status')}
                            validateStatus={validateStatuses['length_of_transport_status[]']}
                            labelCol={{span: 24}}
                        >
                            <Row gutter={1}>
                                <Col lg={8}>
                                    <Select
                                        allowClear
                                        className="a-select a-select--search-content _length-of-transport-status-state"
                                        optionFilterProp="children"
                                        placeholder={t('package:label.status_transport')}
                                        showSearch={true}
                                        value={input['length_of_transport_status[]'] && input['length_of_transport_status[]'].state}
                                        onChange={state => {
                                            let lengthOfTransportStatuses = input['length_of_transport_status[]'] || {};

                                            lengthOfTransportStatuses.state = state || undefined;
                                            lengthOfTransportStatuses = lodash.pickBy(lengthOfTransportStatuses, value => !lodash.isUndefined(value));

                                            return this.changeInput({
                                                'length_of_transport_status[]': Object.keys(lengthOfTransportStatuses).length ? lengthOfTransportStatuses : undefined
                                            });
                                        }}
                                    >
                                        {lodash.values(STATUS_TRANSPORT_PACKAGE).map(action => (
                                            <Option
                                                className={`_a-select-status-transport-${action}` + (action === "INIT" ? " a-hidden" : "")}
                                                key={action}
                                                value={action}
                                            >
                                                {t(`package:status_transport.${action}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col lg={4}>
                                    <DatePicker
                                        className="width-100-pc _length-of-transport-status-time-from"
                                        locale={locale}
                                        placeholder={t('package:placeholder.start')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        value={input['length_of_transport_status[]'] && input['length_of_transport_status[]'].time_from ? moment(input['length_of_transport_status[]'].time_from) : undefined}
                                        onChange={timeFrom => {
                                            let lengthOfTransportStatuses = input['length_of_transport_status[]'] || {};

                                            lengthOfTransportStatuses.time_from = timeFrom ? timeFrom.format('YYYY-MM-DD HH:mm:ss') : undefined;
                                            lengthOfTransportStatuses = lodash.pickBy(lengthOfTransportStatuses, value => !lodash.isUndefined(value));

                                            return this.changeInput({
                                                'length_of_transport_status[]': Object.keys(lengthOfTransportStatuses).length ? lengthOfTransportStatuses : undefined
                                            });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <DatePicker
                                        className="width-100-pc _length-of-transport-status-time-to"
                                        locale={locale}
                                        placeholder={t('package:placeholder.end')}
                                        format="HH:mm:ss DD/MM/YYYY"
                                        showTime={{
                                            defaultValue: moment('23:59:59', 'HH:mm:ss')
                                        }}
                                        value={input['length_of_transport_status[]'] && input['length_of_transport_status[]'].time_to ? moment(input['length_of_transport_status[]'].time_to) : undefined}
                                        onChange={timeTo => {
                                            let lengthOfTransportStatuses = input['length_of_transport_status[]'] || {};

                                            lengthOfTransportStatuses.time_to = timeTo ? timeTo.format('YYYY-MM-DD HH:mm:ss') : undefined;
                                            lengthOfTransportStatuses = lodash.pickBy(lengthOfTransportStatuses, value => !lodash.isUndefined(value));

                                            return this.changeInput({
                                                'length_of_transport_status[]': Object.keys(lengthOfTransportStatuses).length ? lengthOfTransportStatuses : undefined
                                            });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Select
                                        allowClear
                                        className="a-select a-select--search-content _length-of-transport-status-compare"
                                        optionFilterProp="children"
                                        showSearch={true}
                                        value={input['length_of_transport_status[]'] && input['length_of_transport_status[]'].compare}
                                        onChange={compare => {
                                            let lengthOfTransportStatuses = input['length_of_transport_status[]'] || {};

                                            lengthOfTransportStatuses.compare = compare || undefined;
                                            lengthOfTransportStatuses = lodash.pickBy(lengthOfTransportStatuses, value => !lodash.isUndefined(value));

                                            return this.changeInput({
                                                'length_of_transport_status[]': Object.keys(lengthOfTransportStatuses).length ? lengthOfTransportStatuses : undefined
                                            });
                                        }}
                                    >
                                        {[{
                                            name: t('greater'),
                                            value: '>'
                                        }, {
                                            name: t('smaller'),
                                            value: '<'
                                        }, {
                                            name: t('equal'),
                                            value: '='
                                        }].map(comparison => (
                                            <Option
                                                className={`_a-select-status-transport-${comparison.value}`}
                                                key={comparison.value}
                                                value={comparison.value}
                                            >
                                                {comparison.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col lg={4}>
                                    <InputNumber
                                        className="_length-of-transport-status-day"
                                        value={input['length_of_transport_status[]'] && input['length_of_transport_status[]'].day}
                                        suffix={t('day')}
                                        onChange={day => {
                                            let lengthOfTransportStatuses = input['length_of_transport_status[]'] || {};

                                            lengthOfTransportStatuses.day = day || undefined;
                                            lengthOfTransportStatuses = lodash.pickBy(lengthOfTransportStatuses, value => !lodash.isUndefined(value));

                                            return this.changeInput({
                                                'length_of_transport_status[]': Object.keys(lengthOfTransportStatuses).length ? lengthOfTransportStatuses : undefined
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>

                    <Col lg={6} xxl={4}>
                        <Form.Item
                            className="a-form__item search-content"
                            label={t("package:label.is_checked")}
                            labelCol={{span: 24}}>
                            <Select
                                allowClear
                                onChange={this.onChangeMultiSelect.bind(this, 'is_checked')}
                                value={input.is_checked}
                                placeholder={t('package:label.is_checked')}
                            >
                                {[true, false].map(value => (
                                    <Select.Option
                                        key={value}
                                        value={value}
                                    >{value ? t('package:label.checked') : t('package:label.unchecked')}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {
                        !lodash.get(input, 'is_not_in_bag') && 
                        <Col lg={6} xxl={4}>
                            <Form.Item
                                className="a-form__item search-content"
                                label={t('other')}
                                labelCol={{span: 24}}>
                                <Select
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="children"
                                    placeholder={t('other')}
                                    value={input.others || []}
                                    onChange={this.onChangeMultiSelect.bind(this, 'others')}
                                >
                                    {['not_in_bag', 'not_in_order', 'has_operate_order'].map(value => (
                                        <Option
                                            key={value}
                                            value={value}
                                        >
                                            {t(`package:other_options.${value}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    
                </Row>
            </>
        );
    };

    render() {
        const {collapsed} = this.state;
        const {t} = this.props;
        const input = converValue2Int({
            ...this.state.input
        }, ['status_transports', 'others']);

        return (
            <div className="a-content--search-header search-default">
                <Form onSubmit={this.onSubmit} className="a-form">
                    {collapsed ? this.renderCollapsedForm(input) : this.renderExpandedForm(input)}

                    <div className="gflex galign-center gjustify-space-between search--action">
                        <div className="gflex galign-center">
                            <Button
                                className="a-text--link px-0 apr-16"
                                type="link"
                                onClick={this.toggleCollapseSearch}
                            >
                                {collapsed ? (
                                    <>
                                        <Icon className="mr-1" type="down"/>
                                        {t('expand_search')}
                                    </>
                                ) : (
                                    <>
                                        <Icon className="mr-1" type="up"/>
                                        {t('collapse_search')}
                                    </>
                                )}
                            </Button>
                            {
                                !lodash.get(input, 'is_not_in_bag') && 
                                <Link
                                    href={this.getPackagesInWarehouseUrl()}
                                >
                                    {t('package_already_in_warehouse')}
                                </Link>
                            }
                        </div>
                        <div className="gflex galign-center">
                            <a
                                className="link-reload _a-btn-reload-list-package mr-3"
                                href="/"
                                onClick={this.onReset}
                            >
                                <Icon type="sync"/> {t('label.refresh_filter')}
                            </a>
                            <Button
                                className="a-btn a-btn--primary a-btn--search-list _a-btn-search-list-package"
                                htmlType="submit"
                                loading={this.props.loading}
                                type="primary"
                            >
                                {t('btn.search')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

FormSearch.defaultProps = {
    input: {},
    inputVersion: 0,
    loading: false,
    onSearch: () => {
    },
};

FormSearch.propTypes = {
    input: PropTypes.object,
    inputVersion: PropTypes.number,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
};

export default withTranslation()(FormSearch);
